const NoVacations = `<svg width="221" height="220" viewBox="0 0 221 220" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_2133_28961)">
<mask id="path-1-inside-1_2133_28961" fill="white">
<path d="M0.5 100C0.5 44.7715 45.2715 0 100.5 0H120.5C175.728 0 220.5 44.7715 220.5 100V220H0.5V100Z"/>
</mask>
<path d="M0.5 100C0.5 44.7715 45.2715 0 100.5 0H120.5C175.728 0 220.5 44.7715 220.5 100V220H0.5V100Z" fill="#F9F9F9"/>
<path d="M159.183 77.0521C167.118 77.0521 173.552 70.6192 173.552 62.6837C173.552 54.7481 167.118 48.3152 159.183 48.3152C151.249 48.3152 144.815 54.7481 144.815 62.6837C144.815 70.6192 151.249 77.0521 159.183 77.0521Z" fill="#F2F2F2"/>
<path d="M159.183 77.0521C167.118 77.0521 173.552 70.6192 173.552 62.6837C173.552 54.7481 167.118 48.3152 159.183 48.3152C151.249 48.3152 144.815 54.7481 144.815 62.6837C144.815 70.6192 151.249 77.0521 159.183 77.0521Z" fill="url(#paint0_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M159.183 77.0521C167.118 77.0521 173.552 70.6192 173.552 62.6837C173.552 54.7481 167.118 48.3152 159.183 48.3152C151.249 48.3152 144.815 54.7481 144.815 62.6837C144.815 70.6192 151.249 77.0521 159.183 77.0521Z" fill="url(#paint1_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M157.132 37.3684C157.132 36.6127 157.744 36 158.5 36C159.256 36 159.868 36.6127 159.868 37.3684V42.8421C159.868 43.5979 159.256 44.2105 158.5 44.2105C157.744 44.2105 157.132 43.5979 157.132 42.8421V37.3684Z" fill="#F2F2F2"/>
<path d="M157.132 37.3684C157.132 36.6127 157.744 36 158.5 36C159.256 36 159.868 36.6127 159.868 37.3684V42.8421C159.868 43.5979 159.256 44.2105 158.5 44.2105C157.744 44.2105 157.132 43.5979 157.132 42.8421V37.3684Z" fill="url(#paint2_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M157.132 37.3684C157.132 36.6127 157.744 36 158.5 36C159.256 36 159.868 36.6127 159.868 37.3684V42.8421C159.868 43.5979 159.256 44.2105 158.5 44.2105C157.744 44.2105 157.132 43.5979 157.132 42.8421V37.3684Z" fill="url(#paint3_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M157.132 81.1579C157.132 80.4021 157.744 79.7895 158.5 79.7895C159.256 79.7895 159.868 80.4021 159.868 81.1579V86.6316C159.868 87.3873 159.256 88 158.5 88C157.744 88 157.132 87.3873 157.132 86.6316V81.1579Z" fill="#F2F2F2"/>
<path d="M157.132 81.1579C157.132 80.4021 157.744 79.7895 158.5 79.7895C159.256 79.7895 159.868 80.4021 159.868 81.1579V86.6316C159.868 87.3873 159.256 88 158.5 88C157.744 88 157.132 87.3873 157.132 86.6316V81.1579Z" fill="url(#paint4_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M157.132 81.1579C157.132 80.4021 157.744 79.7895 158.5 79.7895C159.256 79.7895 159.868 80.4021 159.868 81.1579V86.6316C159.868 87.3873 159.256 88 158.5 88C157.744 88 157.132 87.3873 157.132 86.6316V81.1579Z" fill="url(#paint5_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M133.868 63.3684C133.113 63.3684 132.5 62.7558 132.5 62C132.5 61.2442 133.113 60.6316 133.868 60.6316H139.342C140.098 60.6316 140.711 61.2442 140.711 62C140.711 62.7558 140.098 63.3684 139.342 63.3684H133.868Z" fill="#F2F2F2"/>
<path d="M133.868 63.3684C133.113 63.3684 132.5 62.7558 132.5 62C132.5 61.2442 133.113 60.6316 133.868 60.6316H139.342C140.098 60.6316 140.711 61.2442 140.711 62C140.711 62.7558 140.098 63.3684 139.342 63.3684H133.868Z" fill="url(#paint6_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M133.868 63.3684C133.113 63.3684 132.5 62.7558 132.5 62C132.5 61.2442 133.113 60.6316 133.868 60.6316H139.342C140.098 60.6316 140.711 61.2442 140.711 62C140.711 62.7558 140.098 63.3684 139.342 63.3684H133.868Z" fill="url(#paint7_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M177.658 63.3684C176.902 63.3684 176.289 62.7558 176.289 62C176.289 61.2442 176.902 60.6316 177.658 60.6316H183.132C183.887 60.6316 184.5 61.2442 184.5 62C184.5 62.7558 183.887 63.3684 183.132 63.3684H177.658Z" fill="#F2F2F2"/>
<path d="M177.658 63.3684C176.902 63.3684 176.289 62.7558 176.289 62C176.289 61.2442 176.902 60.6316 177.658 60.6316H183.132C183.887 60.6316 184.5 61.2442 184.5 62C184.5 62.7558 183.887 63.3684 183.132 63.3684H177.658Z" fill="url(#paint8_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M177.658 63.3684C176.902 63.3684 176.289 62.7558 176.289 62C176.289 61.2442 176.902 60.6316 177.658 60.6316H183.132C183.887 60.6316 184.5 61.2442 184.5 62C184.5 62.7558 183.887 63.3684 183.132 63.3684H177.658Z" fill="url(#paint9_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M140.115 45.5505C139.581 45.0161 139.581 44.1496 140.115 43.6152C140.65 43.0808 141.516 43.0808 142.05 43.6152L145.921 47.4857C146.455 48.0201 146.455 48.8865 145.921 49.4209C145.387 49.9553 144.52 49.9553 143.986 49.4209L140.115 45.5505Z" fill="#F2F2F2"/>
<path d="M140.115 45.5505C139.581 45.0161 139.581 44.1496 140.115 43.6152C140.65 43.0808 141.516 43.0808 142.05 43.6152L145.921 47.4857C146.455 48.0201 146.455 48.8865 145.921 49.4209C145.387 49.9553 144.52 49.9553 143.986 49.4209L140.115 45.5505Z" fill="url(#paint10_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M140.115 45.5505C139.581 45.0161 139.581 44.1496 140.115 43.6152C140.65 43.0808 141.516 43.0808 142.05 43.6152L145.921 47.4857C146.455 48.0201 146.455 48.8865 145.921 49.4209C145.387 49.9553 144.52 49.9553 143.986 49.4209L140.115 45.5505Z" fill="url(#paint11_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M171.079 76.5143C170.545 75.9799 170.545 75.1135 171.079 74.5791C171.613 74.0447 172.48 74.0447 173.014 74.5791L176.885 78.4495C177.419 78.9839 177.419 79.8504 176.885 80.3848C176.35 80.9192 175.484 80.9192 174.95 80.3848L171.079 76.5143Z" fill="#F2F2F2"/>
<path d="M171.079 76.5143C170.545 75.9799 170.545 75.1135 171.079 74.5791C171.613 74.0447 172.48 74.0447 173.014 74.5791L176.885 78.4495C177.419 78.9839 177.419 79.8504 176.885 80.3848C176.35 80.9192 175.484 80.9192 174.95 80.3848L171.079 76.5143Z" fill="url(#paint12_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M171.079 76.5143C170.545 75.9799 170.545 75.1135 171.079 74.5791C171.613 74.0447 172.48 74.0447 173.014 74.5791L176.885 78.4495C177.419 78.9839 177.419 79.8504 176.885 80.3848C176.35 80.9192 175.484 80.9192 174.95 80.3848L171.079 76.5143Z" fill="url(#paint13_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M173.285 42.2515C173.77 41.6726 174.634 41.5971 175.212 42.0828C175.791 42.5686 175.867 43.4318 175.381 44.0107L171.863 48.2038C171.377 48.7828 170.514 48.8583 169.935 48.3725C169.356 47.8867 169.28 47.0235 169.766 46.4446L173.285 42.2515Z" fill="#F2F2F2"/>
<path d="M173.285 42.2515C173.77 41.6726 174.634 41.5971 175.212 42.0828C175.791 42.5686 175.867 43.4318 175.381 44.0107L171.863 48.2038C171.377 48.7828 170.514 48.8583 169.935 48.3725C169.356 47.8867 169.28 47.0235 169.766 46.4446L173.285 42.2515Z" fill="url(#paint14_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M173.285 42.2515C173.77 41.6726 174.634 41.5971 175.212 42.0828C175.791 42.5686 175.867 43.4318 175.381 44.0107L171.863 48.2038C171.377 48.7828 170.514 48.8583 169.935 48.3725C169.356 47.8867 169.28 47.0235 169.766 46.4446L173.285 42.2515Z" fill="url(#paint15_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M145.137 75.7962C145.623 75.2173 146.486 75.1417 147.065 75.6275C147.644 76.1133 147.72 76.9765 147.234 77.5554L143.715 81.7485C143.23 82.3274 142.366 82.403 141.788 81.9172C141.209 81.4314 141.133 80.5682 141.619 79.9893L145.137 75.7962Z" fill="#F2F2F2"/>
<path d="M145.137 75.7962C145.623 75.2173 146.486 75.1417 147.065 75.6275C147.644 76.1133 147.72 76.9765 147.234 77.5554L143.715 81.7485C143.23 82.3274 142.366 82.403 141.788 81.9172C141.209 81.4314 141.133 80.5682 141.619 79.9893L145.137 75.7962Z" fill="url(#paint16_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M145.137 75.7962C145.623 75.2173 146.486 75.1417 147.065 75.6275C147.644 76.1133 147.72 76.9765 147.234 77.5554L143.715 81.7485C143.23 82.3274 142.366 82.403 141.788 81.9172C141.209 81.4314 141.133 80.5682 141.619 79.9893L145.137 75.7962Z" fill="url(#paint17_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M166.56 113.231C167.112 112.274 166.784 111.051 165.828 110.499C164.871 109.947 163.648 110.274 163.096 111.231L65.0957 280.972C64.5434 281.929 64.8712 283.152 65.8278 283.704C66.7843 284.256 68.0075 283.929 68.5598 282.972L166.56 113.231Z" fill="#F2F2F2"/>
<path d="M166.56 113.231C167.112 112.274 166.784 111.051 165.828 110.499C164.871 109.947 163.648 110.274 163.096 111.231L65.0957 280.972C64.5434 281.929 64.8712 283.152 65.8278 283.704C66.7843 284.256 68.0075 283.929 68.5598 282.972L166.56 113.231Z" fill="url(#paint18_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M166.56 113.231C167.112 112.274 166.784 111.051 165.828 110.499C164.871 109.947 163.648 110.274 163.096 111.231L65.0957 280.972C64.5434 281.929 64.8712 283.152 65.8278 283.704C66.7843 284.256 68.0075 283.929 68.5598 282.972L166.56 113.231Z" fill="url(#paint19_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M166.56 113.231C167.112 112.274 166.784 111.051 165.828 110.499C164.871 109.947 163.648 110.274 163.096 111.231L65.0957 280.972C64.5434 281.929 64.8712 283.152 65.8278 283.704C66.7843 284.256 68.0075 283.929 68.5598 282.972L166.56 113.231Z" fill="black" fillOpacity="0.05"/>
<path opacity="0.1" d="M137.964 162.763L134.5 160.763L122.5 181.547L126.825 182.056L137.964 162.763Z" fill="#020202"/>
<path d="M164.638 177.364L168.705 159.467C169.547 155.765 169.862 151.962 169.642 148.172L168.975 136.655C168.604 130.259 165.04 124.48 159.492 121.276C182.368 134.484 195.23 160.002 192.239 186.247L191.485 192.864L164.638 177.364Z" fill="#F2F2F2"/>
<path d="M164.638 177.364L168.705 159.467C169.547 155.765 169.862 151.962 169.642 148.172L168.975 136.655C168.604 130.259 165.04 124.48 159.492 121.276C182.368 134.484 195.23 160.002 192.239 186.247L191.485 192.864L164.638 177.364Z" fill="url(#paint20_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M164.638 177.364L168.705 159.467C169.547 155.765 169.862 151.962 169.642 148.172L168.975 136.655C168.604 130.259 165.04 124.48 159.492 121.276C182.368 134.484 195.23 160.002 192.239 186.247L191.485 192.864L164.638 177.364Z" fill="url(#paint21_linear_2133_28961)" fillOpacity="0.15"/>
<path d="M86.8534 125.402C108.086 109.69 136.617 108.069 159.493 121.276C153.945 118.073 147.157 117.877 141.433 120.753L131.125 125.934C127.733 127.639 124.598 129.813 121.812 132.393L108.347 144.864L81.5 129.364L86.8534 125.402Z" fill="#F2F2F2"/>
<path d="M86.8534 125.402C108.086 109.69 136.617 108.069 159.493 121.276C153.945 118.073 147.157 117.877 141.433 120.753L131.125 125.934C127.733 127.639 124.598 129.813 121.812 132.393L108.347 144.864L81.5 129.364L86.8534 125.402Z" fill="url(#paint22_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M86.8534 125.402C108.086 109.69 136.617 108.069 159.493 121.276C153.945 118.073 147.157 117.877 141.433 120.753L131.125 125.934C127.733 127.639 124.598 129.813 121.812 132.393L108.347 144.864L81.5 129.364L86.8534 125.402Z" fill="url(#paint23_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M86.8534 125.402C108.086 109.69 136.617 108.069 159.493 121.276C153.945 118.073 147.157 117.877 141.433 120.753L131.125 125.934C127.733 127.639 124.598 129.813 121.812 132.393L108.347 144.864L81.5 129.364L86.8534 125.402Z" fill="black" fillOpacity="0.05"/>
<path d="M108.347 144.864L121.812 132.393C124.597 129.813 127.733 127.639 131.125 125.934L141.433 120.753C147.157 117.877 153.944 118.073 159.493 121.276L136.493 161.114L108.347 144.864Z" fill="#F2F2F2"/>
<path d="M108.347 144.864L121.812 132.393C124.597 129.813 127.733 127.639 131.125 125.934L141.433 120.753C147.157 117.877 153.944 118.073 159.493 121.276L136.493 161.114L108.347 144.864Z" fill="url(#paint24_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M108.347 144.864L121.812 132.393C124.597 129.813 127.733 127.639 131.125 125.934L141.433 120.753C147.157 117.877 153.944 118.073 159.493 121.276L136.493 161.114L108.347 144.864Z" fill="url(#paint25_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M159.492 121.276C165.04 124.48 168.604 130.259 168.975 136.655L169.642 148.172C169.862 151.962 169.547 155.765 168.705 159.467L164.638 177.364L136.492 161.114L159.492 121.276Z" fill="#F2F2F2"/>
<path d="M159.492 121.276C165.04 124.48 168.604 130.259 168.975 136.655L169.642 148.172C169.862 151.962 169.547 155.765 168.705 159.467L164.638 177.364L136.492 161.114L159.492 121.276Z" fill="url(#paint26_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M159.492 121.276C165.04 124.48 168.604 130.259 168.975 136.655L169.642 148.172C169.862 151.962 169.547 155.765 168.705 159.467L164.638 177.364L136.492 161.114L159.492 121.276Z" fill="url(#paint27_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M159.492 121.276C165.04 124.48 168.604 130.259 168.975 136.655L169.642 148.172C169.862 151.962 169.547 155.765 168.705 159.467L164.638 177.364L136.492 161.114L159.492 121.276Z" fill="black" fillOpacity="0.05"/>
<path d="M81.5 129.363L108.347 144.863C105.585 149.646 99.4695 151.285 94.6865 148.524L85.1603 143.024C80.3773 140.262 78.7386 134.146 81.5 129.363Z" fill="#D9D9D9"/>
<path d="M81.5 129.363L108.347 144.863C105.585 149.646 99.4695 151.285 94.6865 148.524L85.1603 143.024C80.3773 140.262 78.7386 134.146 81.5 129.363Z" fill="#F2F2F2"/>
<path d="M81.5 129.363L108.347 144.863C105.585 149.646 99.4695 151.285 94.6865 148.524L85.1603 143.024C80.3773 140.262 78.7386 134.146 81.5 129.363Z" fill="url(#paint28_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M81.5 129.363L108.347 144.863C105.585 149.646 99.4695 151.285 94.6865 148.524L85.1603 143.024C80.3773 140.262 78.7386 134.146 81.5 129.363Z" fill="url(#paint29_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M81.5 129.363L108.347 144.863C105.585 149.646 99.4695 151.285 94.6865 148.524L85.1603 143.024C80.3773 140.262 78.7386 134.146 81.5 129.363Z" fill="black" fillOpacity="0.15"/>
<path d="M108.347 144.863L136.493 161.113C133.731 165.896 127.615 167.535 122.832 164.774L112.007 158.524C107.224 155.762 105.585 149.646 108.347 144.863Z" fill="#F2F2F2"/>
<path d="M108.347 144.863L136.493 161.113C133.731 165.896 127.615 167.535 122.832 164.774L112.007 158.524C107.224 155.762 105.585 149.646 108.347 144.863Z" fill="url(#paint30_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M108.347 144.863L136.493 161.113C133.731 165.896 127.615 167.535 122.832 164.774L112.007 158.524C107.224 155.762 105.585 149.646 108.347 144.863Z" fill="url(#paint31_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M108.347 144.863L136.493 161.113C133.731 165.896 127.615 167.535 122.832 164.774L112.007 158.524C107.224 155.762 105.585 149.646 108.347 144.863Z" fill="black" fillOpacity="0.05"/>
<path d="M164.639 177.363L191.485 192.863C188.724 197.646 182.608 199.285 177.825 196.524L168.299 191.024C163.516 188.262 161.877 182.146 164.639 177.363Z" fill="#D9D9D9"/>
<path d="M164.639 177.363L191.485 192.863C188.724 197.646 182.608 199.285 177.825 196.524L168.299 191.024C163.516 188.262 161.877 182.146 164.639 177.363Z" fill="#F2F2F2"/>
<path d="M164.639 177.363L191.485 192.863C188.724 197.646 182.608 199.285 177.825 196.524L168.299 191.024C163.516 188.262 161.877 182.146 164.639 177.363Z" fill="url(#paint32_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M164.639 177.363L191.485 192.863C188.724 197.646 182.608 199.285 177.825 196.524L168.299 191.024C163.516 188.262 161.877 182.146 164.639 177.363Z" fill="url(#paint33_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M164.639 177.363L191.485 192.863C188.724 197.646 182.608 199.285 177.825 196.524L168.299 191.024C163.516 188.262 161.877 182.146 164.639 177.363Z" fill="black" fillOpacity="0.05"/>
<path d="M136.492 161.113L164.638 177.363C161.877 182.146 155.761 183.785 150.978 181.024L140.152 174.774C135.37 172.012 133.731 165.896 136.492 161.113Z" fill="#D9D9D9"/>
<path d="M136.492 161.113L164.638 177.363C161.877 182.146 155.761 183.785 150.978 181.024L140.152 174.774C135.37 172.012 133.731 165.896 136.492 161.113Z" fill="#F2F2F2"/>
<path d="M136.492 161.113L164.638 177.363C161.877 182.146 155.761 183.785 150.978 181.024L140.152 174.774C135.37 172.012 133.731 165.896 136.492 161.113Z" fill="url(#paint34_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M136.492 161.113L164.638 177.363C161.877 182.146 155.761 183.785 150.978 181.024L140.152 174.774C135.37 172.012 133.731 165.896 136.492 161.113Z" fill="url(#paint35_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M136.492 161.113L164.638 177.363C161.877 182.146 155.761 183.785 150.978 181.024L140.152 174.774C135.37 172.012 133.731 165.896 136.492 161.113Z" fill="black" fillOpacity="0.15"/>
<path d="M39.167 142.689H37.167V166.219H39.167V142.689Z" fill="#F2F2F2"/>
<path d="M39.167 142.689H37.167V166.219H39.167V142.689Z" fill="url(#paint36_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M39.167 142.689H37.167V166.219H39.167V142.689Z" fill="url(#paint37_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M39.167 142.689H37.167V166.219H39.167V142.689Z" fill="black" fillOpacity="0.05"/>
<path d="M56.501 142.689H54.501V166.219H56.501V142.689Z" fill="#F2F2F2"/>
<path d="M56.501 142.689H54.501V166.219H56.501V142.689Z" fill="url(#paint38_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M56.501 142.689H54.501V166.219H56.501V142.689Z" fill="url(#paint39_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M56.501 142.689H54.501V166.219H56.501V142.689Z" fill="black" fillOpacity="0.05"/>
<path opacity="0.1" d="M39.167 146.472L37.167 146.723V142.689H39.167V146.472ZM56.5005 142.689H54.5004V146.149L56.5005 145.901V142.689Z" fill="#020202"/>
<path d="M56.7331 140H36.9362C36.7885 140.002 36.644 140.034 36.5089 140.095C36.3737 140.155 36.2512 140.244 36.1506 140.354C36.0469 140.465 35.9652 140.595 35.9118 140.739C35.8584 140.882 35.8301 141.035 35.8332 141.19V142.842C35.8301 142.997 35.8584 143.15 35.9118 143.293C35.9652 143.437 36.0469 143.567 36.1506 143.678C36.2512 143.789 36.3737 143.876 36.5089 143.937C36.644 143.998 36.7885 144.03 36.9362 144.032H40.2985C41.3732 144.057 42.4353 143.778 43.3749 143.226C44.4433 142.646 45.628 142.344 46.8315 142.344C48.035 142.344 49.2197 142.646 50.2881 143.226C51.2277 143.778 52.2898 144.056 53.3645 144.032H56.7268C56.8745 144.03 57.019 143.998 57.1542 143.938C57.2893 143.877 57.4118 143.789 57.5155 143.678C57.6161 143.568 57.6978 143.437 57.7512 143.293C57.8078 143.15 57.8361 142.997 57.8329 142.842V141.19C57.8361 140.879 57.7229 140.579 57.5155 140.356C57.3113 140.133 57.0285 140.005 56.7331 140Z" fill="#3F847A"/>
<path d="M56.7331 140H36.9362C36.7885 140.002 36.644 140.034 36.5089 140.095C36.3737 140.155 36.2512 140.244 36.1506 140.354C36.0469 140.465 35.9652 140.595 35.9118 140.739C35.8584 140.882 35.8301 141.035 35.8332 141.19V142.842C35.8301 142.997 35.8584 143.15 35.9118 143.293C35.9652 143.437 36.0469 143.567 36.1506 143.678C36.2512 143.789 36.3737 143.876 36.5089 143.937C36.644 143.998 36.7885 144.03 36.9362 144.032H40.2985C41.3732 144.057 42.4353 143.778 43.3749 143.226C44.4433 142.646 45.628 142.344 46.8315 142.344C48.035 142.344 49.2197 142.646 50.2881 143.226C51.2277 143.778 52.2898 144.056 53.3645 144.032H56.7268C56.8745 144.03 57.019 143.998 57.1542 143.938C57.2893 143.877 57.4118 143.789 57.5155 143.678C57.6161 143.568 57.6978 143.437 57.7512 143.293C57.8078 143.15 57.8361 142.997 57.8329 142.842V141.19C57.8361 140.879 57.7229 140.579 57.5155 140.356C57.3113 140.133 57.0285 140.005 56.7331 140Z" fill="#F2F2F2"/>
<path d="M56.7331 140H36.9362C36.7885 140.002 36.644 140.034 36.5089 140.095C36.3737 140.155 36.2512 140.244 36.1506 140.354C36.0469 140.465 35.9652 140.595 35.9118 140.739C35.8584 140.882 35.8301 141.035 35.8332 141.19V142.842C35.8301 142.997 35.8584 143.15 35.9118 143.293C35.9652 143.437 36.0469 143.567 36.1506 143.678C36.2512 143.789 36.3737 143.876 36.5089 143.937C36.644 143.998 36.7885 144.03 36.9362 144.032H40.2985C41.3732 144.057 42.4353 143.778 43.3749 143.226C44.4433 142.646 45.628 142.344 46.8315 142.344C48.035 142.344 49.2197 142.646 50.2881 143.226C51.2277 143.778 52.2898 144.056 53.3645 144.032H56.7268C56.8745 144.03 57.019 143.998 57.1542 143.938C57.2893 143.877 57.4118 143.789 57.5155 143.678C57.6161 143.568 57.6978 143.437 57.7512 143.293C57.8078 143.15 57.8361 142.997 57.8329 142.842V141.19C57.8361 140.879 57.7229 140.579 57.5155 140.356C57.3113 140.133 57.0285 140.005 56.7331 140Z" fill="url(#paint40_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M56.7331 140H36.9362C36.7885 140.002 36.644 140.034 36.5089 140.095C36.3737 140.155 36.2512 140.244 36.1506 140.354C36.0469 140.465 35.9652 140.595 35.9118 140.739C35.8584 140.882 35.8301 141.035 35.8332 141.19V142.842C35.8301 142.997 35.8584 143.15 35.9118 143.293C35.9652 143.437 36.0469 143.567 36.1506 143.678C36.2512 143.789 36.3737 143.876 36.5089 143.937C36.644 143.998 36.7885 144.03 36.9362 144.032H40.2985C41.3732 144.057 42.4353 143.778 43.3749 143.226C44.4433 142.646 45.628 142.344 46.8315 142.344C48.035 142.344 49.2197 142.646 50.2881 143.226C51.2277 143.778 52.2898 144.056 53.3645 144.032H56.7268C56.8745 144.03 57.019 143.998 57.1542 143.938C57.2893 143.877 57.4118 143.789 57.5155 143.678C57.6161 143.568 57.6978 143.437 57.7512 143.293C57.8078 143.15 57.8361 142.997 57.8329 142.842V141.19C57.8361 140.879 57.7229 140.579 57.5155 140.356C57.3113 140.133 57.0285 140.005 56.7331 140Z" fill="url(#paint41_linear_2133_28961)" fillOpacity="0.05"/>
<g opacity="0.2">
<path d="M60.0943 166.218H34.2392C33.2792 166.218 32.5 166.994 32.5 167.951V214.233C32.5 215.19 33.2792 215.966 34.2392 215.966H60.0943C61.0543 215.966 61.8335 215.19 61.8335 214.233V167.951C61.8335 166.994 61.0543 166.218 60.0943 166.218Z" fill="#020202"/>
<path d="M60.0943 166.218H34.2392C33.2792 166.218 32.5 166.994 32.5 167.951V214.233C32.5 215.19 33.2792 215.966 34.2392 215.966H60.0943C61.0543 215.966 61.8335 215.19 61.8335 214.233V167.951C61.8335 166.994 61.0543 166.218 60.0943 166.218Z" fill="url(#paint42_linear_2133_28961)"/>
</g>
<path d="M70.7603 166.218H44.9053C43.9453 166.218 43.166 166.994 43.166 167.951V214.233C43.166 215.19 43.9453 215.966 44.9053 215.966H70.7603C71.7203 215.966 72.4996 215.19 72.4996 214.233V167.951C72.4996 166.994 71.7203 166.218 70.7603 166.218Z" fill="#F2F2F2"/>
<path d="M70.7603 166.218H44.9053C43.9453 166.218 43.166 166.994 43.166 167.951V214.233C43.166 215.19 43.9453 215.966 44.9053 215.966H70.7603C71.7203 215.966 72.4996 215.19 72.4996 214.233V167.951C72.4996 166.994 71.7203 166.218 70.7603 166.218Z" fill="url(#paint43_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M70.7603 166.218H44.9053C43.9453 166.218 43.166 166.994 43.166 167.951V214.233C43.166 215.19 43.9453 215.966 44.9053 215.966H70.7603C71.7203 215.966 72.4996 215.19 72.4996 214.233V167.951C72.4996 166.994 71.7203 166.218 70.7603 166.218Z" fill="url(#paint44_linear_2133_28961)" fillOpacity="0.05"/>
<path opacity="0.1" d="M48.1686 211.932C47.9045 211.932 47.6469 211.831 47.4601 211.652C47.2733 211.474 47.167 211.227 47.167 210.975V171.878C47.167 171.753 47.1928 171.628 47.2443 171.512C47.2926 171.396 47.3667 171.291 47.4601 171.202C47.5535 171.113 47.663 171.043 47.7854 170.996C47.9078 170.948 48.0366 170.924 48.1686 170.924C48.4327 170.924 48.6872 171.024 48.874 171.203C49.0608 171.382 49.167 171.625 49.167 171.878V210.975C49.167 211.101 49.1412 211.224 49.0929 211.341C49.0414 211.458 48.9674 211.563 48.8772 211.652C48.7838 211.742 48.6743 211.812 48.5519 211.859C48.4295 211.908 48.3007 211.932 48.1686 211.932Z" fill="#020202"/>
<path opacity="0.1" d="M54.834 211.932C54.5703 211.932 54.3131 211.831 54.1266 211.652C53.9401 211.474 53.834 211.227 53.834 210.975V171.878C53.834 171.753 53.8597 171.628 53.9112 171.512C53.9594 171.396 54.0333 171.291 54.1266 171.202C54.2198 171.113 54.3291 171.043 54.4513 170.996C54.5735 170.948 54.7022 170.924 54.834 170.924C54.9658 170.924 55.0944 170.948 55.2166 170.996C55.3388 171.043 55.4481 171.113 55.5414 171.202C55.6346 171.291 55.7086 171.396 55.7568 171.512C55.8083 171.628 55.834 171.753 55.834 171.878V210.975C55.834 211.227 55.7279 211.474 55.5414 211.652C55.3549 211.831 55.0977 211.932 54.834 211.932Z" fill="#020202"/>
<path opacity="0.1" d="M60.833 211.932C60.5693 211.932 60.3121 211.831 60.1256 211.652C59.9391 211.474 59.833 211.227 59.833 210.975V171.878C59.833 171.753 59.8587 171.628 59.9102 171.512C59.9584 171.396 60.0324 171.291 60.1256 171.202C60.2189 171.113 60.3282 171.043 60.4504 170.996C60.5726 170.948 60.7012 170.924 60.833 170.924C61.0967 170.924 61.3507 171.024 61.5404 171.203C61.7269 171.382 61.833 171.625 61.833 171.878V210.975C61.833 211.227 61.7269 211.474 61.5404 211.652C61.3539 211.831 61.0967 211.932 60.833 211.932Z" fill="#020202"/>
<path opacity="0.1" d="M67.5 211.932C67.2363 211.932 66.9791 211.831 66.7926 211.652C66.6061 211.474 66.5 211.227 66.5 210.975V171.878C66.5 171.625 66.6061 171.382 66.7926 171.203C66.9823 171.024 67.2363 170.924 67.5 170.924C67.6318 170.924 67.7604 170.948 67.8826 170.996C68.0048 171.043 68.1141 171.113 68.2074 171.202C68.3006 171.291 68.3746 171.396 68.4228 171.512C68.4743 171.628 68.5 171.753 68.5 171.878V210.975C68.5 211.227 68.3939 211.474 68.2074 211.652C68.0209 211.831 67.7637 211.932 67.5 211.932Z" fill="#020202"/>
<path opacity="0.2" d="M59.168 219.999C60.2728 219.999 61.168 219.097 61.168 217.983C61.168 216.869 60.2728 215.966 59.168 215.966C58.0631 215.966 57.168 216.869 57.168 217.983C57.168 219.097 58.0631 219.999 59.168 219.999Z" fill="#020202"/>
<path opacity="0.2" d="M70.5 217.983C70.5 218.38 70.3813 218.771 70.1625 219.105C69.9437 219.436 69.6312 219.695 69.2656 219.846C68.9 220 68.4968 220.038 68.1093 219.96C67.7218 219.884 67.3655 219.692 67.0843 219.408C66.8061 219.124 66.6155 218.765 66.5374 218.374C66.4624 217.983 66.4999 217.577 66.653 217.208C66.8061 216.839 67.0624 216.524 67.3905 216.303C67.7218 216.082 68.1062 215.966 68.5031 215.966C68.7656 215.966 69.025 216.016 69.2687 216.12C69.5094 216.221 69.7313 216.369 69.9156 216.555C70.1031 216.744 70.25 216.965 70.35 217.211C70.45 217.457 70.5 217.718 70.5 217.983Z" fill="#020202"/>
<path opacity="0.2" d="M38.4991 217.983C38.4991 218.38 38.3803 218.771 38.1615 219.105C37.9428 219.436 37.6303 219.695 37.2646 219.846C36.899 220 36.4959 220.038 36.1083 219.96C35.7208 219.884 35.3646 219.692 35.0833 219.408C34.8052 219.124 34.6145 218.765 34.5364 218.374C34.4614 217.983 34.4989 217.577 34.652 217.208C34.8052 216.839 35.0614 216.524 35.3896 216.303C35.7208 216.082 36.1052 215.966 36.5021 215.966C36.7646 215.966 37.024 216.016 37.2677 216.12C37.5084 216.221 37.7303 216.369 37.9147 216.555C38.1022 216.744 38.2491 216.965 38.3491 217.211C38.4491 217.457 38.4991 217.718 38.4991 217.983Z" fill="#020202"/>
<path opacity="0.2" d="M46.501 219.999C47.6058 219.999 48.501 219.097 48.501 217.983C48.501 216.869 47.6058 215.966 46.501 215.966C45.3961 215.966 44.501 216.869 44.501 217.983C44.501 219.097 45.3961 219.999 46.501 219.999Z" fill="#020202"/>
<path d="M46.5006 218.655C46.8689 218.655 47.1673 218.354 47.1673 217.983C47.1673 217.611 46.8689 217.311 46.5006 217.311C46.1324 217.311 45.834 217.611 45.834 217.983C45.834 218.354 46.1324 218.655 46.5006 218.655Z" fill="#9ABCCF"/>
<path d="M46.5006 218.655C46.8689 218.655 47.1673 218.354 47.1673 217.983C47.1673 217.611 46.8689 217.311 46.5006 217.311C46.1324 217.311 45.834 217.611 45.834 217.983C45.834 218.354 46.1324 218.655 46.5006 218.655Z" fill="#F2F2F2"/>
<path d="M46.5006 218.655C46.8689 218.655 47.1673 218.354 47.1673 217.983C47.1673 217.611 46.8689 217.311 46.5006 217.311C46.1324 217.311 45.834 217.611 45.834 217.983C45.834 218.354 46.1324 218.655 46.5006 218.655Z" fill="url(#paint45_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M46.5006 218.655C46.8689 218.655 47.1673 218.354 47.1673 217.983C47.1673 217.611 46.8689 217.311 46.5006 217.311C46.1324 217.311 45.834 217.611 45.834 217.983C45.834 218.354 46.1324 218.655 46.5006 218.655Z" fill="url(#paint46_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M68.4997 218.655C68.8679 218.655 69.1664 218.354 69.1664 217.983C69.1664 217.611 68.8679 217.311 68.4997 217.311C68.1314 217.311 67.833 217.611 67.833 217.983C67.833 218.354 68.1314 218.655 68.4997 218.655Z" fill="#9ABCCF"/>
<path d="M68.4997 218.655C68.8679 218.655 69.1664 218.354 69.1664 217.983C69.1664 217.611 68.8679 217.311 68.4997 217.311C68.1314 217.311 67.833 217.611 67.833 217.983C67.833 218.354 68.1314 218.655 68.4997 218.655Z" fill="#F2F2F2"/>
<path d="M68.4997 218.655C68.8679 218.655 69.1664 218.354 69.1664 217.983C69.1664 217.611 68.8679 217.311 68.4997 217.311C68.1314 217.311 67.833 217.611 67.833 217.983C67.833 218.354 68.1314 218.655 68.4997 218.655Z" fill="url(#paint47_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M68.4997 218.655C68.8679 218.655 69.1664 218.354 69.1664 217.983C69.1664 217.611 68.8679 217.311 68.4997 217.311C68.1314 217.311 67.833 217.611 67.833 217.983C67.833 218.354 68.1314 218.655 68.4997 218.655Z" fill="url(#paint48_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M37.1664 217.983C37.1664 218.116 37.126 218.246 37.0534 218.357C36.9809 218.468 36.876 218.552 36.7551 218.603C36.6341 218.655 36.4997 218.669 36.3707 218.641C36.2417 218.617 36.1207 218.552 36.0293 218.457C35.9353 218.365 35.8707 218.246 35.8465 218.113C35.8197 217.983 35.8331 217.847 35.8842 217.725C35.9353 217.603 36.0186 217.498 36.1288 217.424C36.239 217.351 36.368 217.311 36.4997 217.311C36.6771 217.311 36.8465 217.381 36.9701 217.508C37.0965 217.633 37.1664 217.804 37.1664 217.983Z" fill="#9ABCCF"/>
<path d="M37.1664 217.983C37.1664 218.116 37.126 218.246 37.0534 218.357C36.9809 218.468 36.876 218.552 36.7551 218.603C36.6341 218.655 36.4997 218.669 36.3707 218.641C36.2417 218.617 36.1207 218.552 36.0293 218.457C35.9353 218.365 35.8707 218.246 35.8465 218.113C35.8197 217.983 35.8331 217.847 35.8842 217.725C35.9353 217.603 36.0186 217.498 36.1288 217.424C36.239 217.351 36.368 217.311 36.4997 217.311C36.6771 217.311 36.8465 217.381 36.9701 217.508C37.0965 217.633 37.1664 217.804 37.1664 217.983Z" fill="#F2F2F2"/>
<path d="M37.1664 217.983C37.1664 218.116 37.126 218.246 37.0534 218.357C36.9809 218.468 36.876 218.552 36.7551 218.603C36.6341 218.655 36.4997 218.669 36.3707 218.641C36.2417 218.617 36.1207 218.552 36.0293 218.457C35.9353 218.365 35.8707 218.246 35.8465 218.113C35.8197 217.983 35.8331 217.847 35.8842 217.725C35.9353 217.603 36.0186 217.498 36.1288 217.424C36.239 217.351 36.368 217.311 36.4997 217.311C36.6771 217.311 36.8465 217.381 36.9701 217.508C37.0965 217.633 37.1664 217.804 37.1664 217.983Z" fill="url(#paint49_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M37.1664 217.983C37.1664 218.116 37.126 218.246 37.0534 218.357C36.9809 218.468 36.876 218.552 36.7551 218.603C36.6341 218.655 36.4997 218.669 36.3707 218.641C36.2417 218.617 36.1207 218.552 36.0293 218.457C35.9353 218.365 35.8707 218.246 35.8465 218.113C35.8197 217.983 35.8331 217.847 35.8842 217.725C35.9353 217.603 36.0186 217.498 36.1288 217.424C36.239 217.351 36.368 217.311 36.4997 217.311C36.6771 217.311 36.8465 217.381 36.9701 217.508C37.0965 217.633 37.1664 217.804 37.1664 217.983Z" fill="url(#paint50_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M59.8333 217.983C59.8333 218.116 59.793 218.246 59.7204 218.357C59.6479 218.468 59.543 218.552 59.4221 218.603C59.3011 218.655 59.1667 218.669 59.0377 218.641C58.9087 218.617 58.7877 218.552 58.6963 218.457C58.6023 218.365 58.5377 218.246 58.5135 218.113C58.4867 217.983 58.5001 217.847 58.5512 217.725C58.6022 217.603 58.6856 217.498 58.7958 217.424C58.906 217.351 59.035 217.311 59.1667 217.311C59.3441 217.311 59.5135 217.381 59.6371 217.508C59.7635 217.633 59.8333 217.804 59.8333 217.983Z" fill="#9ABCCF"/>
<path d="M59.8333 217.983C59.8333 218.116 59.793 218.246 59.7204 218.357C59.6479 218.468 59.543 218.552 59.4221 218.603C59.3011 218.655 59.1667 218.669 59.0377 218.641C58.9087 218.617 58.7877 218.552 58.6963 218.457C58.6023 218.365 58.5377 218.246 58.5135 218.113C58.4867 217.983 58.5001 217.847 58.5512 217.725C58.6022 217.603 58.6856 217.498 58.7958 217.424C58.906 217.351 59.035 217.311 59.1667 217.311C59.3441 217.311 59.5135 217.381 59.6371 217.508C59.7635 217.633 59.8333 217.804 59.8333 217.983Z" fill="#F2F2F2"/>
<path d="M59.8333 217.983C59.8333 218.116 59.793 218.246 59.7204 218.357C59.6479 218.468 59.543 218.552 59.4221 218.603C59.3011 218.655 59.1667 218.669 59.0377 218.641C58.9087 218.617 58.7877 218.552 58.6963 218.457C58.6023 218.365 58.5377 218.246 58.5135 218.113C58.4867 217.983 58.5001 217.847 58.5512 217.725C58.6022 217.603 58.6856 217.498 58.7958 217.424C58.906 217.351 59.035 217.311 59.1667 217.311C59.3441 217.311 59.5135 217.381 59.6371 217.508C59.7635 217.633 59.8333 217.804 59.8333 217.983Z" fill="url(#paint51_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M59.8333 217.983C59.8333 218.116 59.793 218.246 59.7204 218.357C59.6479 218.468 59.543 218.552 59.4221 218.603C59.3011 218.655 59.1667 218.669 59.0377 218.641C58.9087 218.617 58.7877 218.552 58.6963 218.457C58.6023 218.365 58.5377 218.246 58.5135 218.113C58.4867 217.983 58.5001 217.847 58.5512 217.725C58.6022 217.603 58.6856 217.498 58.7958 217.424C58.906 217.351 59.035 217.311 59.1667 217.311C59.3441 217.311 59.5135 217.381 59.6371 217.508C59.7635 217.633 59.8333 217.804 59.8333 217.983Z" fill="url(#paint52_linear_2133_28961)" fillOpacity="0.05"/>
<path d="M68.95 86.759C68.0536 86.759 67.3468 86.4451 66.8296 85.8172C66.3125 85.1893 66.0539 84.3583 66.0539 83.3241C66.0539 82.3269 66.1573 81.4035 66.3642 80.554C66.6055 79.6676 66.9675 78.7996 67.4502 77.9501C67.9674 77.0637 68.6397 76.1773 69.4672 75.2909C70.0188 74.663 70.4498 74.109 70.7601 73.6288C71.1049 73.1487 71.3462 72.7054 71.4841 72.2992C71.622 71.856 71.691 71.4127 71.691 70.9695C71.691 70.12 71.3979 69.4737 70.8118 69.0305C70.2257 68.5873 69.381 68.3657 68.2777 68.3657C67.1744 68.3657 66.1401 68.4949 65.1747 68.7535C64.2093 69.012 63.2784 69.3998 62.382 69.9169C61.3477 70.5078 60.434 70.711 59.641 70.5263C58.848 70.3047 58.2274 69.8615 57.7792 69.1967C57.331 68.5319 57.0724 67.7562 57.0034 66.8698C56.969 65.9834 57.1931 65.1154 57.6758 64.2659C58.1929 63.4164 58.9859 62.7147 60.0547 62.1607C61.4683 61.422 62.9681 60.8864 64.5541 60.554C66.1401 60.1847 67.6226 60 69.0017 60C71.3807 60 73.4666 60.4063 75.2594 61.2188C77.0868 62.0314 78.5004 63.1764 79.5002 64.6537C80.5001 66.0942 81 67.7932 81 69.7507C81 70.8587 80.8449 71.9114 80.5345 72.9086C80.2243 73.9058 79.7243 74.8846 79.0348 75.8449C78.3452 76.7682 77.3798 77.7101 76.1386 78.6704C75.0698 79.5199 74.2424 80.277 73.6562 80.9418C73.0701 81.5697 72.6391 82.1791 72.3633 82.7701C72.122 83.361 71.9323 84.0074 71.7944 84.7091C71.6565 85.3001 71.3635 85.7987 70.9152 86.205C70.467 86.5743 69.812 86.759 68.95 86.759ZM68.7949 100C67.2089 100 65.9504 99.5014 65.0195 98.5042C64.1231 97.5069 63.6749 96.1958 63.6749 94.5706C63.6749 92.9825 64.1231 91.7082 65.0195 90.7479C65.9504 89.7507 67.2089 89.2521 68.7949 89.2521C70.3808 89.2521 71.622 89.7507 72.5185 90.7479C73.4149 91.7082 73.8631 92.9825 73.8631 94.5706C73.8631 96.1958 73.4149 97.5069 72.5185 98.5042C71.622 99.5014 70.3808 100 68.7949 100Z" fill="url(#paint53_linear_2133_28961)"/>
</g>
<path d="M0.5 0H220.5H0.5ZM220.5 221H0.5V219H220.5V221ZM0.5 220V0V220ZM220.5 0V220V0Z" fill="url(#paint54_linear_2133_28961)" mask="url(#path-1-inside-1_2133_28961)"/>
<defs>
<linearGradient id="paint0_linear_2133_28961" x1="158.78" y1="88" x2="158.78" y2="36" gradientUnits="userSpaceOnUse">
<stop stop-color="#FE9800"/>
<stop offset="1" stop-color="#FE9800" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint1_linear_2133_28961" x1="132.5" y1="62" x2="184.5" y2="62" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint2_linear_2133_28961" x1="158.78" y1="88" x2="158.78" y2="36" gradientUnits="userSpaceOnUse">
<stop stop-color="#FE9800"/>
<stop offset="1" stop-color="#FE9800" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint3_linear_2133_28961" x1="132.5" y1="62" x2="184.5" y2="62" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint4_linear_2133_28961" x1="158.78" y1="88" x2="158.78" y2="36" gradientUnits="userSpaceOnUse">
<stop stop-color="#FE9800"/>
<stop offset="1" stop-color="#FE9800" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint5_linear_2133_28961" x1="132.5" y1="62" x2="184.5" y2="62" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint6_linear_2133_28961" x1="158.78" y1="88" x2="158.78" y2="36" gradientUnits="userSpaceOnUse">
<stop stop-color="#FE9800"/>
<stop offset="1" stop-color="#FE9800" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint7_linear_2133_28961" x1="132.5" y1="62" x2="184.5" y2="62" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint8_linear_2133_28961" x1="158.78" y1="88" x2="158.78" y2="36" gradientUnits="userSpaceOnUse">
<stop stop-color="#FE9800"/>
<stop offset="1" stop-color="#FE9800" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint9_linear_2133_28961" x1="132.5" y1="62" x2="184.5" y2="62" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint10_linear_2133_28961" x1="158.78" y1="88" x2="158.78" y2="36" gradientUnits="userSpaceOnUse">
<stop stop-color="#FE9800"/>
<stop offset="1" stop-color="#FE9800" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint11_linear_2133_28961" x1="132.5" y1="62" x2="184.5" y2="62" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint12_linear_2133_28961" x1="158.78" y1="88" x2="158.78" y2="36" gradientUnits="userSpaceOnUse">
<stop stop-color="#FE9800"/>
<stop offset="1" stop-color="#FE9800" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint13_linear_2133_28961" x1="132.5" y1="62" x2="184.5" y2="62" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint14_linear_2133_28961" x1="158.78" y1="88" x2="158.78" y2="36" gradientUnits="userSpaceOnUse">
<stop stop-color="#FE9800"/>
<stop offset="1" stop-color="#FE9800" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint15_linear_2133_28961" x1="132.5" y1="62" x2="184.5" y2="62" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint16_linear_2133_28961" x1="158.78" y1="88" x2="158.78" y2="36" gradientUnits="userSpaceOnUse">
<stop stop-color="#FE9800"/>
<stop offset="1" stop-color="#FE9800" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint17_linear_2133_28961" x1="132.5" y1="62" x2="184.5" y2="62" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint18_linear_2133_28961" x1="65.8464" y1="283.715" x2="165.846" y2="110.51" gradientUnits="userSpaceOnUse">
<stop stop-color="#FE9800"/>
<stop offset="1" stop-color="#FE9800" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint19_linear_2133_28961" x1="114.096" y1="196.102" x2="117.56" y2="198.102" gradientUnits="userSpaceOnUse">
<stop stop-opacity="0"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint20_linear_2133_28961" x1="164.284" y1="177.159" x2="187.284" y2="137.322" gradientUnits="userSpaceOnUse">
<stop stop-color="#FE9800"/>
<stop offset="1" stop-color="#FE9800" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint21_linear_2133_28961" x1="202.985" y1="172.945" x2="175.488" y2="157.07" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint22_linear_2133_28961" x1="109.292" y1="145.409" x2="132.292" y2="105.572" gradientUnits="userSpaceOnUse">
<stop stop-color="#FE9800"/>
<stop offset="1" stop-color="#FE9800" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint23_linear_2133_28961" x1="93" y1="109.445" x2="147.993" y2="141.195" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint24_linear_2133_28961" x1="122.571" y1="153.076" x2="145.571" y2="113.239" gradientUnits="userSpaceOnUse">
<stop stop-color="#FE9800"/>
<stop offset="1" stop-color="#FE9800" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint25_linear_2133_28961" x1="147.993" y1="141.195" x2="133.92" y2="133.07" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint26_linear_2133_28961" x1="150.716" y1="169.326" x2="173.716" y2="129.489" gradientUnits="userSpaceOnUse">
<stop stop-color="#FE9800"/>
<stop offset="1" stop-color="#FE9800" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint27_linear_2133_28961" x1="147.992" y1="141.195" x2="176.138" y2="157.445" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint28_linear_2133_28961" x1="90.0677" y1="145.857" x2="95.0677" y2="137.197" gradientUnits="userSpaceOnUse">
<stop stop-color="#FE9800"/>
<stop offset="1" stop-color="#FE9800" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint29_linear_2133_28961" x1="79" y1="133.693" x2="105.847" y2="149.193" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint30_linear_2133_28961" x1="116.914" y1="161.357" x2="121.914" y2="152.697" gradientUnits="userSpaceOnUse">
<stop stop-color="#FE9800"/>
<stop offset="1" stop-color="#FE9800" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint31_linear_2133_28961" x1="105.847" y1="149.193" x2="132.693" y2="164.693" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint32_linear_2133_28961" x1="173.206" y1="193.857" x2="178.206" y2="185.197" gradientUnits="userSpaceOnUse">
<stop stop-color="#FE9800"/>
<stop offset="1" stop-color="#FE9800" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint33_linear_2133_28961" x1="162.139" y1="181.693" x2="188.985" y2="197.193" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint34_linear_2133_28961" x1="145.931" y1="178.11" x2="150.931" y2="169.449" gradientUnits="userSpaceOnUse">
<stop stop-color="#FE9800"/>
<stop offset="1" stop-color="#FE9800" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint35_linear_2133_28961" x1="134.425" y1="165.693" x2="162.138" y2="181.693" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint36_linear_2133_28961" x1="38.1778" y1="166.219" x2="38.1778" y2="142.689" gradientUnits="userSpaceOnUse">
<stop stop-color="#FE9800"/>
<stop offset="1" stop-color="#FE9800" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint37_linear_2133_28961" x1="37.167" y1="154.454" x2="39.167" y2="154.454" gradientUnits="userSpaceOnUse">
<stop stop-opacity="0"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint38_linear_2133_28961" x1="55.5117" y1="166.219" x2="55.5117" y2="142.689" gradientUnits="userSpaceOnUse">
<stop stop-color="#FE9800"/>
<stop offset="1" stop-color="#FE9800" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint39_linear_2133_28961" x1="54.501" y1="154.454" x2="56.501" y2="154.454" gradientUnits="userSpaceOnUse">
<stop stop-opacity="0"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint40_linear_2133_28961" x1="46.9514" y1="144.034" x2="46.9514" y2="140" gradientUnits="userSpaceOnUse">
<stop stop-color="#FE9800"/>
<stop offset="1" stop-color="#FE9800" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint41_linear_2133_28961" x1="46.9158" y1="144.034" x2="46.9158" y2="140" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint42_linear_2133_28961" x1="32.5" y1="191.092" x2="43.1667" y2="191.092" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-opacity="0.2"/>
</linearGradient>
<linearGradient id="paint43_linear_2133_28961" x1="57.9905" y1="215.966" x2="57.9905" y2="166.218" gradientUnits="userSpaceOnUse">
<stop stop-color="#FE9800"/>
<stop offset="1" stop-color="#FE9800" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint44_linear_2133_28961" x1="43.166" y1="191.092" x2="72.4996" y2="191.092" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint45_linear_2133_28961" x1="46.5078" y1="218.655" x2="46.5078" y2="217.311" gradientUnits="userSpaceOnUse">
<stop stop-color="#FE9800"/>
<stop offset="1" stop-color="#FE9800" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint46_linear_2133_28961" x1="45.834" y1="217.983" x2="47.1673" y2="217.983" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint47_linear_2133_28961" x1="68.5068" y1="218.655" x2="68.5068" y2="217.311" gradientUnits="userSpaceOnUse">
<stop stop-color="#FE9800"/>
<stop offset="1" stop-color="#FE9800" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint48_linear_2133_28961" x1="67.833" y1="217.983" x2="69.1664" y2="217.983" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint49_linear_2133_28961" x1="36.5068" y1="218.655" x2="36.5068" y2="217.311" gradientUnits="userSpaceOnUse">
<stop stop-color="#FE9800"/>
<stop offset="1" stop-color="#FE9800" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint50_linear_2133_28961" x1="35.833" y1="217.983" x2="37.1664" y2="217.983" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint51_linear_2133_28961" x1="59.1738" y1="218.655" x2="59.1738" y2="217.311" gradientUnits="userSpaceOnUse">
<stop stop-color="#FE9800"/>
<stop offset="1" stop-color="#FE9800" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint52_linear_2133_28961" x1="58.5" y1="217.983" x2="59.8333" y2="217.983" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint53_linear_2133_28961" x1="69" y1="60" x2="69" y2="100" gradientUnits="userSpaceOnUse">
<stop stop-color="#FE9800"/>
<stop offset="1" stop-color="#E88D04"/>
</linearGradient>
<linearGradient id="paint54_linear_2133_28961" x1="220.5" y1="220" x2="0.500003" y2="220" gradientUnits="userSpaceOnUse">
<stop stop-color="#DADADA" stop-opacity="0"/>
<stop offset="0.5" stop-color="#DADADA"/>
<stop offset="1" stop-color="#DADADA" stop-opacity="0"/>
</linearGradient>
<clipPath id="clip0_2133_28961">
<path d="M0.5 100C0.5 44.7715 45.2715 0 100.5 0H120.5C175.728 0 220.5 44.7715 220.5 100V220H0.5V100Z" fill="white"/>
</clipPath>
</defs>
</svg>
`;

export default NoVacations;
