import React from "react";

export const NoTransportImage = (
  <svg xmlns="http://www.w3.org/2000/svg" width="220" height="220" viewBox="0 0 220 220" fill="none">
  <mask id="path-1-inside-1_4201_14337" fill="white">
    <path d="M0 100C0 44.7715 44.7715 0 100 0H120C175.228 0 220 44.7715 220 100V220H0V100Z"/>
  </mask>
  <path d="M0 100C0 44.7715 44.7715 0 100 0H120C175.228 0 220 44.7715 220 100V220H0V100Z" fill="#FAFAFA"/>
  <path d="M0 0H220H0ZM220 221H0V219H220V221ZM0 220V0V220ZM220 0V220V0Z" fill="url(#paint0_linear_4201_14337)" mask="url(#path-1-inside-1_4201_14337)"/>
  <path d="M26 199H146V208H30C27.7909 208 26 206.209 26 204V199Z" fill="#F0F0F0"/>
  <path d="M26 199H146V208H30C27.7909 208 26 206.209 26 204V199Z" fill="url(#paint1_linear_4201_14337)" fill-opacity="0.05"/>
  <path d="M26 199H146V208H30C27.7909 208 26 206.209 26 204V199Z" fill="black" fill-opacity="0.05"/>
  <path d="M26 115C26 112.791 27.7909 111 30 111H142C144.209 111 146 112.791 146 115V199H26V115Z" fill="#F0F0F0"/>
  <path d="M26 115C26 112.791 27.7909 111 30 111H142C144.209 111 146 112.791 146 115V199H26V115Z" fill="url(#paint2_linear_4201_14337)" fill-opacity="0.05"/>
  <path d="M126 111H142C144.209 111 146 112.791 146 115V199H126V111Z" fill="black" fill-opacity="0.03"/>
  <path d="M104 171C104 169.895 104.895 169 106 169H132C133.105 169 134 169.895 134 171C134 172.105 133.105 173 132 173H106C104.895 173 104 172.105 104 171Z" fill="#F6F6F6"/>
  <path d="M104 185C104 183.895 104.895 183 106 183H132C133.105 183 134 183.895 134 185C134 186.105 133.105 187 132 187H106C104.895 187 104 186.105 104 185Z" fill="#F6F6F6"/>
  <path d="M70 111H102V138.172C102 139.953 99.8457 140.846 98.5858 139.586L95.1764 136.176C94.4946 135.495 93.4236 135.396 92.6291 135.942L87.1331 139.721C86.4506 140.19 85.5494 140.19 84.8669 139.721L79.3709 135.942C78.5764 135.396 77.5054 135.495 76.8236 136.176L73.4142 139.586C72.1543 140.846 70 139.953 70 138.172V111Z" fill="black" fill-opacity="0.05"/>
  <path d="M194 204C194 206.209 192.209 208 190 208H146V140H171.343C172.404 140 173.421 140.421 174.172 141.172L192.828 159.828C193.579 160.579 194 161.596 194 162.657V204Z" fill="#F0F0F0"/>
  <path d="M194 204C194 206.209 192.209 208 190 208H146V140H171.343C172.404 140 173.421 140.421 174.172 141.172L192.828 159.828C193.579 160.579 194 161.596 194 162.657V204Z" fill="url(#paint3_linear_4201_14337)" fill-opacity="0.05"/>
  <path d="M194 204C194 206.209 192.209 208 190 208H146V140H171.343C172.404 140 173.421 140.421 174.172 141.172L192.828 159.828C193.579 160.579 194 161.596 194 162.657V204Z" fill="black" fill-opacity="0.05"/>
  <path d="M177 208H190C192.209 208 194 206.209 194 204V162.657C194 161.596 193.579 160.579 192.828 159.828L174.172 141.172C173.421 140.421 172.404 140 171.343 140H156L177 161V208Z" fill="#F0F0F0"/>
  <path d="M177 208H190C192.209 208 194 206.209 194 204V162.657C194 161.596 193.579 160.579 192.828 159.828L174.172 141.172C173.421 140.421 172.404 140 171.343 140H156L177 161V208Z" fill="url(#paint4_linear_4201_14337)" fill-opacity="0.05"/>
  <path d="M185 174C185 175.105 184.105 176 183 176H157C155.895 176 155 175.105 155 174V154C155 152.895 155.895 152 157 152H172.172C172.702 152 173.211 152.211 173.586 152.586L184.414 163.414C184.789 163.789 185 164.298 185 164.828V174Z" fill="#F6F6F6"/>
  <circle cx="57" cy="205" r="15" fill="#F0F0F0"/>
  <circle cx="57" cy="205" r="15" fill="url(#paint5_linear_4201_14337)" fill-opacity="0.05"/>
  <circle cx="57" cy="205" r="15" fill="url(#paint6_linear_4201_14337)" fill-opacity="0.1"/>
  <circle cx="57" cy="205" r="15" fill="black" fill-opacity="0.1"/>
  <circle cx="57" cy="205" r="6" fill="#F6F6F6"/>
  <circle cx="167" cy="205" r="15" fill="#F0F0F0"/>
  <circle cx="167" cy="205" r="15" fill="url(#paint7_linear_4201_14337)" fill-opacity="0.05"/>
  <circle cx="167" cy="205" r="15" fill="url(#paint8_linear_4201_14337)" fill-opacity="0.1"/>
  <circle cx="167" cy="205" r="15" fill="black" fill-opacity="0.1"/>
  <circle cx="167" cy="205" r="6" fill="#F6F6F6"/>
  <path d="M109.95 62.759C109.054 62.759 108.347 62.4451 107.83 61.8172C107.312 61.1893 107.054 60.3583 107.054 59.3241C107.054 58.3269 107.157 57.4035 107.364 56.554C107.606 55.6676 107.968 54.7996 108.45 53.9501C108.967 53.0637 109.64 52.1773 110.467 51.2909C111.019 50.663 111.45 50.109 111.76 49.6288C112.105 49.1487 112.346 48.7054 112.484 48.2992C112.622 47.856 112.691 47.4127 112.691 46.9695C112.691 46.12 112.398 45.4737 111.812 45.0305C111.226 44.5873 110.381 44.3657 109.278 44.3657C108.174 44.3657 107.14 44.4949 106.175 44.7535C105.209 45.012 104.278 45.3998 103.382 45.9169C102.348 46.5078 101.434 46.711 100.641 46.5263C99.848 46.3047 99.2274 45.8615 98.7792 45.1967C98.331 44.5319 98.0724 43.7562 98.0034 42.8698C97.969 41.9834 98.1931 41.1154 98.6758 40.2659C99.1929 39.4164 99.9859 38.7147 101.055 38.1607C102.468 37.422 103.968 36.8864 105.554 36.554C107.14 36.1847 108.623 36 110.002 36C112.381 36 114.467 36.4063 116.259 37.2188C118.087 38.0314 119.5 39.1764 120.5 40.6537C121.5 42.0942 122 43.7932 122 45.7507C122 46.8587 121.845 47.9114 121.535 48.9086C121.224 49.9058 120.724 50.8846 120.035 51.8449C119.345 52.7682 118.38 53.7101 117.139 54.6704C116.07 55.5199 115.242 56.277 114.656 56.9418C114.07 57.5697 113.639 58.1791 113.363 58.7701C113.122 59.361 112.932 60.0074 112.794 60.7091C112.657 61.3001 112.363 61.7987 111.915 62.205C111.467 62.5743 110.812 62.759 109.95 62.759ZM109.795 76C108.209 76 106.95 75.5014 106.02 74.5042C105.123 73.5069 104.675 72.1958 104.675 70.5706C104.675 68.9825 105.123 67.7082 106.02 66.7479C106.95 65.7507 108.209 65.2521 109.795 65.2521C111.381 65.2521 112.622 65.7507 113.518 66.7479C114.415 67.7082 114.863 68.9825 114.863 70.5706C114.863 72.1958 114.415 73.5069 113.518 74.5042C112.622 75.5014 111.381 76 109.795 76Z" fill="url(#paint9_linear_4201_14337)"/>
  <defs>
    <linearGradient id="paint0_linear_4201_14337" x1="220" y1="220" x2="2.563e-06" y2="220" gradientUnits="userSpaceOnUse">
      <stop stop-color="#DADADA" stop-opacity="0"/>
      <stop offset="0.5" stop-color="#DADADA"/>
      <stop offset="1" stop-color="#DADADA" stop-opacity="0"/>
    </linearGradient>
    <linearGradient id="paint1_linear_4201_14337" x1="146" y1="203.5" x2="26" y2="203.5" gradientUnits="userSpaceOnUse">
      <stop stop-color="#FE9800"/>
      <stop offset="1" stop-color="#FE9800" stop-opacity="0"/>
    </linearGradient>
    <linearGradient id="paint2_linear_4201_14337" x1="86.6452" y1="199" x2="86.6452" y2="111" gradientUnits="userSpaceOnUse">
      <stop stop-color="#FE9800"/>
      <stop offset="1" stop-color="#FE9800" stop-opacity="0"/>
    </linearGradient>
    <linearGradient id="paint3_linear_4201_14337" x1="170.258" y1="208" x2="170.258" y2="140" gradientUnits="userSpaceOnUse">
      <stop stop-color="#FE9800"/>
      <stop offset="1" stop-color="#FE9800" stop-opacity="0"/>
    </linearGradient>
    <linearGradient id="paint4_linear_4201_14337" x1="175.204" y1="208" x2="175.204" y2="140" gradientUnits="userSpaceOnUse">
      <stop stop-color="#FE9800"/>
      <stop offset="1" stop-color="#FE9800" stop-opacity="0"/>
    </linearGradient>
    <linearGradient id="paint5_linear_4201_14337" x1="57.1613" y1="220" x2="57.1613" y2="190" gradientUnits="userSpaceOnUse">
      <stop stop-color="#FE9800"/>
      <stop offset="1" stop-color="#FE9800" stop-opacity="0"/>
    </linearGradient>
    <linearGradient id="paint6_linear_4201_14337" x1="42" y1="205" x2="72" y2="205" gradientUnits="userSpaceOnUse">
      <stop stop-opacity="0"/>
      <stop offset="1"/>
    </linearGradient>
    <linearGradient id="paint7_linear_4201_14337" x1="167.161" y1="220" x2="167.161" y2="190" gradientUnits="userSpaceOnUse">
      <stop stop-color="#FE9800"/>
      <stop offset="1" stop-color="#FE9800" stop-opacity="0"/>
    </linearGradient>
    <linearGradient id="paint8_linear_4201_14337" x1="152" y1="205" x2="182" y2="205" gradientUnits="userSpaceOnUse">
      <stop stop-opacity="0"/>
      <stop offset="1"/>
    </linearGradient>
    <linearGradient id="paint9_linear_4201_14337" x1="110" y1="36" x2="110" y2="76" gradientUnits="userSpaceOnUse">
      <stop stop-color="#FE9800"/>
      <stop offset="1" stop-color="#E88D04"/>
    </linearGradient>
  </defs>
</svg>
);
