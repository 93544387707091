import { useRef, useState } from "react";
import {
  dotIcon,
  FloorIcon,
  helpIcon,
  humansIcon,
  spreadIcon,
  StaffListScanIcon,
} from "../../icons/icons";
import OutsideClickHandler from "../Staff/components/OutsideClickHandler";
import QRcode from "../Staff/components/QRcode";
import SpaceChange from "../Staff/components/SpaceChange";
import { Employee } from "../Staff/StaffTypes";
import classes from "./StaffList.module.css";

type Props = {
  employees: Employee[];
  setActiveTab: (value) => void;
  selectedEmployee: any;
  setSelectedEmployee: (employee: any) => void;
};

const StaffList = ({
  employees,
  setActiveTab,
  selectedEmployee,
  setSelectedEmployee,
}: Props) => {
  const [selectedSpace, setSelectedSpace] = useState<string | undefined>(
    "All Spaces"
  ); // Initialize selectedSpace as undefined

  const extractUniqueSpaces = (employees: Employee[]) => {
    const allSpaces = employees.flatMap(
      (employee) =>
        employee.staffSchedules &&
        employee.staffSchedules.map((schedule) => schedule.floor)
    );
    const uniqueSpaces = Array.from(new Set(allSpaces));

    // Add "All Spaces" if it's not already in the uniqueSpaces array
    if (!uniqueSpaces.includes("All Spaces")) {
      uniqueSpaces.unshift("All Spaces");
    }

    return uniqueSpaces;
  };

  const uniqueSpaces = extractUniqueSpaces(employees);

  const handleSpaceChange = (newSpace: string | undefined) => {
    setSelectedSpace(newSpace);
  };

  const filteredEmployees =
    selectedSpace === "All Spaces"
      ? employees.filter((employee) =>
          employee.staffSchedules && employee.staffSchedules?.length > 0
            ? employee
            : null
        )
      : employees.filter((employee) => {
          return (
            employee.staffSchedules &&
            employee.staffSchedules.some(
              (schedule) => schedule.floor === selectedSpace
            )
          );
        });

  const loggedInEmployees = filteredEmployees.filter((employee) => {
    return (
      employee.staffSchedules &&
      employee.staffSchedules.some((schedule) => schedule.checkStatus === true)
    );
  });

  const DEFAULT_IMAGE = "/staffImages/barmen.png";
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });

  const handleToggleSpreadButton = (
    employee: any,
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation(); // Prevent the event from propagating to the outer container

    const buttonRect = e.currentTarget.getBoundingClientRect();
    const modalWidth = 100;

    setModalPosition({
      top: buttonRect.top + buttonRect.height + 10, // 10px below the button's center
      left: buttonRect.right - modalWidth, // Right upper corner of the button
    });

    setSelectedEmployee(employee);
    setTabOpen(true);
  };

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  const [qrButton, setQrButton] = useState(false);
  const employeeActionsRef = useRef<HTMLDivElement | null>(null);
  const [tabOpen, setTabOpen] = useState(false);

  return (
    <>
      {qrButton ? (
        <QRcode setQrButton={setQrButton} />
      ) : (
        <div className={classes.StaffList}>
          <div className={classes.Head}>
            <div className={classes.HeadTitle}>
              <span className={classes.HeadTitleText}>Working staff</span>
              <span className={classes.HeadTitleValue}>
                active {loggedInEmployees.length} /{" "}
                {
                  filteredEmployees.filter((employee) =>
                    employee.staffSchedules?.some(
                      (schedule) => schedule.status === "WORKING"
                    )
                  ).length
                }{" "}
                workers
              </span>
            </div>
            <div className={classes.HeadActions}>
              <SpaceChange
                space={selectedSpace || ""}
                onSpaceChange={handleSpaceChange}
                spaces={uniqueSpaces}
              />
            </div>
          </div>
          <div className={classes.Content}>
            <div className={classes.List}>
              {filteredEmployees
                .filter((employee) =>
                  employee.staffSchedules?.some(
                    (schedule) => schedule.status === "WORKING"
                  )
                )
                .map((employee) => (
                  <div
                    key={employee.id}
                    className={`${classes.EmployeeRow} ${
                      employee.staffSchedules?.[0].checkStatus === true
                        ? classes.Checked
                        : classes.NotChecked
                    }`}
                  >
                    <div
                      className={`${classes.EmployeeNameContainer} ${
                        employee.staffSchedules?.[0].checkStatus === true
                          ? classes.LoggedIn
                          : classes.NotLoggedIn
                      }`}
                    >
                      <div className={classes.EmployeeAvatarContainer}>
                        <img
                          className={classes.EmployeeAvatar}
                          src={
                            employee.avatar?.startsWith("avatar_")
                              ? require(`../../assets/${employee?.avatar}`)
                                  .default
                              : employee?.avatar !== null &&
                                !employee?.avatar?.startsWith("avatar_")
                              ? employee?.avatarUrl
                              : DEFAULT_IMAGE
                          }
                        />

                        <span
                          className={`${classes.WorkDot} ${
                            employee.staffSchedules?.[0].checkStatus === true
                              ? classes.GreenDot
                              : classes.RedDot
                          }`}
                        >
                          {dotIcon}
                        </span>
                      </div>
                      <div className={classes.EmployeeTitleContainer}>
                        <p
                          className={classes.EmployeeName}
                          style={{ marginBottom: 0 }}
                        >
                          {employee.username}
                        </p>
                        <div className={classes.EmployeeSpace}>
                          <span className={classes.EmployeeSpaceIcon}>
                            {employee.staffSchedules?.[0].checkStatus === true
                              ? FloorIcon
                              : helpIcon}
                          </span>
                          <span className={classes.EmployeeSpaceName}>
                            {employee.staffSchedules?.[0].checkStatus === true
                              ? employee.staffSchedules?.[0].floor
                              : "Pending..."}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      ref={employeeActionsRef}
                      className={classes.EmployeeActionsContainer}
                    >
                      <OutsideClickHandler
                        innerRef={employeeActionsRef}
                        onClose={() => setTabOpen(false)}
                      />
                      {employee.staffSchedules?.[0].checkStatus === false ? (
                        <button
                          className={classes.ScanButton}
                          onClick={() => setQrButton(true)}
                        >
                          <span>{StaffListScanIcon}</span>
                        </button>
                      ) : null}

                      <button
                        className={classes.EmployeeActionButton}
                        onClick={(e) => handleToggleSpreadButton(employee, e)}
                      >
                        <span className={classes.EmployeeActionIcon}>
                          {spreadIcon}
                        </span>
                      </button>
                      {selectedEmployee && tabOpen && (
                        <div
                          className={classes.ButtonModal}
                          style={{
                            position: "fixed",
                            top: modalPosition.top + "px",
                            left: modalPosition.left + "px",
                            zIndex: 999,
                          }}
                          onMouseLeave={() => setTabOpen(false)}
                        >
                          <button
                            className={classes.ModalButton}
                            onClick={() => handleTabClick("details")}
                          >
                            Details
                          </button>
                          <button
                            className={classes.ModalButton}
                            onClick={() => handleTabClick("time")}
                          >
                            TimeTable
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <button className={classes.ViewStaffButton}>
            <span className={classes.ButtonIcon}>{humansIcon}</span>
            <span className={classes.ButtonText}>View all staff</span>
          </button>
        </div>
      )}
    </>
  );
};
export default StaffList;
