export const categoriesOptions = [
  { value: 'AFRICAN_CUISINE', label: 'African Cuisine' },
  { value: 'AMERICAN_CUISINE', label: 'American Cuisine' },
  { value: 'ASIAN_CUISINE', label: 'Asian Cuisine' },
  { value: 'AUSTRALIAN_CUISINE', label: 'Australian Cuisine' },
  { value: 'BRAZILIAN_CUISINE', label: 'Brazilian Cuisine' },
  { value: 'CARIBBEAN_CUISINE', label: 'Caribbean Cuisine' },
  { value: 'CATERING', label: 'Catering' },
  { value: 'CHINESE_CUISINE', label: 'Chinese Cuisine' },
  { value: 'COFFEE', label: 'Coffee' },
  { value: 'EUROPEAN_CUISINE', label: 'European Cuisine' },
  { value: 'FRENCH_CUISINE', label: 'French Cuisine' },
  { value: 'GREEK_CUISINE', label: 'Greek Cuisine' },
  { value: 'INDIAN_CUISINE', label: 'Indian Cuisine' },
  { value: 'ITALIAN_CUISINE', label: 'Italian Cuisine' },
  { value: 'JAPANESE_CUISINE', label: 'Japanese Cuisine' },
  { value: 'KOREAN_CUISINE', label: 'Korean Cuisine' },
  { value: 'MEDITERRANEAN_CUISINE', label: 'Mediterranean Cuisine' },
  { value: 'MEXICAN_CUISINE', label: 'Mexican Cuisine' },
  { value: 'SPANISH_CUISINE', label: 'Spanish Cuisine' },
  { value: 'THAI_CUISINE', label: 'Thai Cuisine' },
  { value: 'VIETNAMESE_CUISINE', label: 'Vietnamese Cuisine' },
  { value: 'MIDDLE_EASTERN_CUISINE', label: 'Middle Eastern Cuisine' },
  { value: 'RUSSIAN_CUISINE', label: 'Russian Cuisine' },
  { value: 'TURKISH_CUISINE', label: 'Turkish Cuisine' },
  { value: 'CAJUN_CUISINE', label: 'Cajun Cuisine' },
  { value: 'PERUVIAN_CUISINE', label: 'Peruvian Cuisine' },
  { value: 'SCANDINAVIAN_CUISINE', label: 'Scandinavian Cuisine' },
  { value: 'TEX_MEX_CUISINE', label: 'Tex-Mex Cuisine' },
  { value: 'FUSION_CUISINE', label: 'Fusion Cuisine' },
  { value: 'VEGETARIAN_CUISINE', label: 'Vegetarian Cuisine' },
  { value: 'VEGAN_CUISINE', label: 'Vegan Cuisine' },
  { value: 'SEAFOOD_CUISINE', label: 'Seafood Cuisine' },
  { value: 'FAST_FOOD', label: 'Fast Food' },
  { value: 'DESSERTS_PASTRIES', label: 'Desserts & Pastries' },
  { value: 'BEVERAGES', label: 'Beverages' },
  { value: 'SUSHI', label: 'Sushi' },
  { value: 'DIM_SUM', label: 'Dim Sum' },
  { value: 'BBQ', label: 'BBQ' },
  { value: 'ETHIOPIAN_CUISINE', label: 'Ethiopian Cuisine' },
  { value: 'IRISH_CUISINE', label: 'Irish Cuisine' },
  { value: 'JAMAICAN_CUISINE', label: 'Jamaican Cuisine' },
  { value: 'MOROCCAN_CUISINE', label: 'Moroccan Cuisine' },
  { value: 'POLISH_CUISINE', label: 'Polish Cuisine' },
  { value: 'SWISS_CUISINE', label: 'Swiss Cuisine' },
  { value: 'MIXED_CUISINE', label: 'Mixed Cuisine' },
];

export const workingHours = [
  { value: "00:30", label: "00:30" },
  { value: "01:00", label: "01:00" },
  { value: "01:30", label: "01:30" },
  { value: "02:00", label: "02:00" },
  { value: "02:30", label: "02:30" },
  { value: "03:00", label: "03:00" },
  { value: "03:30", label: "03:30" },
  { value: "04:00", label: "04:00" },
  { value: "04:30", label: "04:30" },
  { value: "05:00", label: "05:00" },
  { value: "05:30", label: "05:30" },
  { value: "06:00", label: "06:00" },
  { value: "06:30", label: "06:30" },
  { value: "07:00", label: "07:00" },
  { value: "07:30", label: "07:30" },
  { value: "08:00", label: "08:00" },
  { value: "08:30", label: "08:30" },
  { value: "09:00", label: "09:00" },
  { value: "09:30", label: "09:30" },
  { value: "10:00", label: "10:00" },
  { value: "10:30", label: "10:30" },
  { value: "11:00", label: "11:00" },
  { value: "11:30", label: "11:30" },
  { value: "12:00", label: "12:00" },
  { value: "12:30", label: "12:30" },
  { value: "13:00", label: "13:00" },
  { value: "13:30", label: "13:30" },
  { value: "14:00", label: "14:00" },
  { value: "14:30", label: "14:30" },
  { value: "15:00", label: "15:00" },
  { value: "15:30", label: "15:30" },
  { value: "16:00", label: "16:00" },
  { value: "16:30", label: "16:30" },
  { value: "17:00", label: "17:00" },
  { value: "17:30", label: "17:30" },
  { value: "18:00", label: "18:00" },
  { value: "18:30", label: "18:30" },
  { value: "19:00", label: "19:00" },
  { value: "19:30", label: "19:30" },
  { value: "20:00", label: "20:00" },
  { value: "20:30", label: "20:30" },
  { value: "21:00", label: "21:00" },
  { value: "21:30", label: "21:30" },
  { value: "22:00", label: "22:00" },
  { value: "22:30", label: "22:30" },
  { value: "23:00", label: "23:00" },
  { value: "23:30", label: "23:30" },
  { value: "00:00", label: "00:00" },
];