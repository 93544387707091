import Cookies from "js-cookie";

const storagePrefix = "rezervare_ta_";

const storage = {
  getAccessToken: () => {
    if (typeof window === "undefined") return null;

    const accessToken = Cookies.get(`${storagePrefix}token`);

    if (
      !accessToken ||
      (typeof accessToken === "string" && accessToken === "undefined")
    ) {
      return null;
    }

    return JSON.parse(accessToken)

  },
  getRefreshToken: () => {
    if (typeof window === "undefined") return null;

    const refreshToken = Cookies.get(`${storagePrefix}refresh_token`);

    if(!refreshToken) {
      return null;
    }

    return JSON.parse(refreshToken);
  },
  setAccessToken: (accessToken) => {
    if (typeof window === "undefined") return;

    Cookies.set(`${storagePrefix}token`, JSON.stringify(accessToken), {
      secure: true,
      sameSite: "strict",
    });
  },
  setRefreshToken: (refreshToken) => {
    if (typeof window === "undefined") return;

    Cookies.set(`${storagePrefix}refresh_token`, JSON.stringify(refreshToken), {
      secure: true,
      sameSite: "strict",
    });
  },
  clearToken: () => {
    if (typeof window === "undefined") return;

    Cookies.remove(`${storagePrefix}token`);
    Cookies.remove(`${storagePrefix}refresh_token`);

    window.location.reload();
  },
};

export default storage;
