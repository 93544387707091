import React, { useState, useEffect } from "react";
import { Employee, Reservation } from "../../StaffTypes";
import classes from "./EditEmployeeSales.module.css";
import Select from "react-select";
import {
  getReservationsByFilter,
  getOrderByReservationId,
  getAllProduts,
  getOrder,
} from "src/auth/api/requests";

type EditEmployeeSalesProps = {
  employee: Employee;
};
type Orders = {
  id: string;
  quantity: string;
  productId: string;
  reservationId: string;
};
interface Ingredient {
  id: string;
  name: string;
  grams: number;
  unit?: { value: string; label: string };
  ingredient?: {
    id: string;
    name: string;
  };
}
interface Product {
  id: string;
  title: string;
  image: string;
  productIngredients: Ingredient[];
  price: string;
  weight: number;
  category: {
    id: string;
    name: string;
  };
  allergens: string;
  recipe: string;
  preparationTime: number;
  preparationZone: any;
}

const EditEmployeeSales: React.FC<EditEmployeeSalesProps> = ({ employee }) => {
  const [salesPercentage, setSalesPercentage] = useState(5);
  const [reservations, setReservations] = useState<Reservation[]>([]);
  const [orders, setOrders] = useState<Orders[]>([]);
  const [generalOrders, setGeneralOrders] = useState<Orders[]>([]);
  const [products, setProduct] = useState<Product[]>([]);

  useEffect(() => {
    const fetchAllReservations = async () => {
      try {
        let allReservations: Reservation[] = [];
        let currentPage = 1;
        let totalPages = 1;

        while (currentPage <= totalPages) {
          const data = await getReservationsByFilter(currentPage);
          const reservationsPage = data.data.map((reservation: any) => ({
            id: reservation.id,
            waiterId: reservation.waiterId,
          }));
          allReservations = [...allReservations, ...reservationsPage];
          totalPages = data.pagination.totalPages;
          currentPage++;
        }

        const filteredReservations = allReservations.filter(
          (reservation) => reservation.waiterId === employee.id
        );

        setReservations(filteredReservations);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchAllReservations();
  }, [employee.id]);

  useEffect(() => {
    const fetchGeneralOrders = async () => {
      try {
        let allOrders: Orders[] = [];
        let currentPage = 1;
        let totalPages = 1;

        while (currentPage <= totalPages) {
          const data = await getOrder(currentPage);
          const ordersPage = data.data.map((order: any) => ({
            id: order.id,
            quantity: parseFloat(order.quantity),
            productId: order.productId,
            reservationId: order.reservationId,
          }));
          allOrders = [...allOrders, ...ordersPage];
          totalPages = data.pagination.totalPages;
          currentPage++;
        }
        setGeneralOrders(allOrders);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchGeneralOrders();
  }, []);

  useEffect(() => {
    const filterOrdersByReservations = () => {
      // Create a set of reservation IDs for quick lookup
      const reservationIds = new Set(
        reservations.map((reservation) => reservation.id)
      );
      // Filter orders based on reservation IDs
      const filteredOrders = generalOrders.filter((order) =>
        reservationIds.has(order.reservationId)
      );
      setOrders(filteredOrders);
    };
    filterOrdersByReservations();
  }, [reservations, generalOrders]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedRestaurant = JSON.parse(
          localStorage.getItem("selectedRestaurant") ?? "null"
        );

        const productData = await getAllProduts(storedRestaurant.id);
        const filteredProduct = productData.map((product: any) => ({
          id: product.id,
          category: {
            id: product.category.id,
            name: product.category.name,
          },
          price: parseFloat(product.price),
          title: product.title,
        }));
        setProduct(filteredProduct);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchData();
  }, []);

  // Filter generalOrders based on reservationId present in reservations
  const filteredGeneralOrders = generalOrders.filter((order) =>
    reservations.some((reservation) => reservation.id === order.reservationId)
  );
  const mergedData = filteredGeneralOrders.map((generalOrder) => {
    const product = products.find(
      (product) =>
        product.id ===
        orders.find((order) => order.id === generalOrder.id)?.productId
    );
    const order = orders.find((order) => order.id === generalOrder.id);
    return {
      id: generalOrder.id,
      title: product?.title ?? "",
      category: product?.category.name ?? "",
      quantity: order?.quantity ?? 0,
      price: product?.price ?? 0,
    };
  });

  // const mergedData = orders.map((order) => {
  //   const products = product.find((product) => product.id === order.productId);
  //   return {
  //     id: order.id,
  //     title: products?.title ?? "",
  //     category: products?.category.name ?? "",
  //     quantity: parseFloat(order.quantity.toString()),
  //     price: parseFloat(products?.price.toString() ?? "0"),
  //   };
  // });
  const TotalSold = mergedData.reduce(
    (acc, product) =>
      acc +
      parseFloat(product.quantity.toString()) *
        parseFloat(product.price.toString()),
    0
  );
  const SalaryFromSales = (TotalSold * salesPercentage) / 100;

  const percentageOptions = Array.from({ length: 30 }, (_, i) => ({
    value: i + 1,
    label: `${i + 1}%`,
  }));

  const handleSelectChange = (selectedOption: any) => {
    setSalesPercentage(selectedOption.value);
  };
  return (
    <div className={classes.BoxForm}>
      <table className={classes.table}>
        <thead>
          <tr>
            <th>Product Name</th>
            <th>Category</th>
            <th>Quantity</th>
            <th>Price per unit</th>
            <th>Total Sold</th>
          </tr>
        </thead>
        <tbody>
          {mergedData.map((product, index) => (
            <tr key={index}>
              <td>{product.title}</td>
              <td>{product.category}</td>
              <td>{product.quantity}</td>
              <td>{product.price} MDL</td>
              <td>
                {parseFloat(product.quantity.toString()) *
                  parseFloat(product.price.toString())}{" "}
                MDL
              </td>
            </tr>
          ))}
          <tr>
            <td colSpan={4} className={classes.total}>
              Total Vandut
            </td>
            <td>{TotalSold} MDL</td>
          </tr>
          <tr>
            <td colSpan={4} className={classes.procent}>
              Procent de vanzari
            </td>
            <td>
              <Select
                value={percentageOptions.find(
                  (option) => option.value === salesPercentage
                )}
                onChange={handleSelectChange}
                options={percentageOptions}
              />
            </td>
          </tr>
          <tr>
            <td colSpan={4} className={classes.salariu}>
              Salariu din % de vanzari
            </td>
            <td>{SalaryFromSales} MDL</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default EditEmployeeSales;
