const NoEmployees = `<svg width="220" height="220" viewBox="0 0 220 220" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_329_5537)">
<mask id="path-1-inside-1_329_5537" fill="white">
<path d="M0 100C0 44.7715 44.7715 0 100 0H120C175.228 0 220 44.7715 220 100V220H0V100Z"/>
</mask>
<path d="M0 100C0 44.7715 44.7715 0 100 0H120C175.228 0 220 44.7715 220 100V220H0V100Z" fill="#F9F9F9"/>
<path d="M228.882 238.912L146 184.5L128.5 201.5L154.942 238.912L228.882 238.912Z" fill="#D9D9D9"/>
<path d="M228.882 238.912L146 184.5L128.5 201.5L154.942 238.912L228.882 238.912Z" fill="#F2F2F2"/>
<path d="M228.882 238.912L146 184.5L128.5 201.5L154.942 238.912L228.882 238.912Z" fill="url(#paint0_linear_329_5537)" fillOpacity="0.05"/>
<path d="M45.4 130.2C44.4111 128.882 45.3519 127 47 127H172C173.648 127 174.589 128.882 173.6 130.2L171.2 133.4C170.445 134.407 169.259 135 168 135H51C49.741 135 48.5554 134.407 47.8 133.4L45.4 130.2Z" fill="#F2F2F2"/>
<path d="M45.4 130.2C44.4111 128.882 45.3519 127 47 127H172C173.648 127 174.589 128.882 173.6 130.2L171.2 133.4C170.445 134.407 169.259 135 168 135H51C49.741 135 48.5554 134.407 47.8 133.4L45.4 130.2Z" fill="url(#paint1_linear_329_5537)" fillOpacity="0.05"/>
<path d="M45.4 130.2C44.4111 128.882 45.3519 127 47 127H172C173.648 127 174.589 128.882 173.6 130.2L171.2 133.4C170.445 134.407 169.259 135 168 135H51C49.741 135 48.5554 134.407 47.8 133.4L45.4 130.2Z" fill="url(#paint2_linear_329_5537)" fillOpacity="0.05"/>
<rect x="107" y="59" width="6" height="10" fill="#F2F2F2"/>
<rect x="107" y="59" width="6" height="10" fill="url(#paint3_linear_329_5537)" fillOpacity="0.05"/>
<rect x="107" y="59" width="6" height="10" fill="url(#paint4_linear_329_5537)" fillOpacity="0.05"/>
<rect x="107" y="59" width="6" height="10" fill="black" fillOpacity="0.05"/>
<path d="M50 127C50 93.8629 76.8629 67 110 67C143.137 67 170 93.8629 170 127H50Z" fill="#F2F2F2"/>
<path d="M50 127C50 93.8629 76.8629 67 110 67C143.137 67 170 93.8629 170 127H50Z" fill="url(#paint5_linear_329_5537)" fillOpacity="0.05"/>
<path d="M50 127C50 93.8629 76.8629 67 110 67C143.137 67 170 93.8629 170 127H50Z" fill="url(#paint6_linear_329_5537)" fillOpacity="0.05"/>
<path opacity="0.05" d="M95 81.4033C88.4685 84.7001 82.6218 89.1558 77.7301 94.5M67.9106 109C68.9068 106.931 70.0181 104.928 71.2362 103" stroke="#020202" strokeWidth="6" strokeLinecap="round"/>
<circle cx="110" cy="50" r="10" fill="#F2F2F2"/>
<circle cx="110" cy="50" r="10" fill="url(#paint7_linear_329_5537)" fillOpacity="0.05"/>
<circle cx="110" cy="50" r="10" fill="url(#paint8_linear_329_5537)" fillOpacity="0.05"/>
<path d="M107 143.5C107 138.806 110.806 135 115.5 135C120.194 135 124 138.806 124 143.5V150L128.873 157.147C130.589 159.663 131.551 162.617 131.646 165.661L132 177L115 194L81.3443 171.563C76.0051 168.003 72.3137 162.454 71.0944 156.154L67 135H86V140.816C86 147.214 89.0617 153.227 94.2366 156.99L100.729 162.22C102.32 163.502 104.622 163.378 106.067 161.933L107 161V143.5Z" fill="#F2F2F2"/>
<path d="M107 143.5C107 138.806 110.806 135 115.5 135C120.194 135 124 138.806 124 143.5V150L128.873 157.147C130.589 159.663 131.551 162.617 131.646 165.661L132 177L115 194L81.3443 171.563C76.0051 168.003 72.3137 162.454 71.0944 156.154L67 135H86V140.816C86 147.214 89.0617 153.227 94.2366 156.99L100.729 162.22C102.32 163.502 104.622 163.378 106.067 161.933L107 161V143.5Z" fill="url(#paint9_linear_329_5537)" fillOpacity="0.05"/>
<path d="M107 143.5C107 138.806 110.806 135 115.5 135C120.194 135 124 138.806 124 143.5V150L128.873 157.147C130.589 159.663 131.551 162.617 131.646 165.661L132 177L115 194L81.3443 171.563C76.0051 168.003 72.3137 162.454 71.0944 156.154L67 135H86V140.816C86 147.214 89.0617 153.227 94.2366 156.99L100.729 162.22C102.32 163.502 104.622 163.378 106.067 161.933L107 161V143.5Z" fill="url(#paint10_linear_329_5537)" fillOpacity="0.05"/>
<rect x="112" y="196.284" width="40" height="16" rx="4" transform="rotate(-45 112 196.284)" fill="#F2F2F2"/>
<rect x="112" y="196.284" width="40" height="16" rx="4" transform="rotate(-45 112 196.284)" fill="url(#paint11_linear_329_5537)" fillOpacity="0.05"/>
<rect x="112" y="196.284" width="40" height="16" rx="4" transform="rotate(-45 112 196.284)" fill="url(#paint12_linear_329_5537)" fillOpacity="0.05"/>
<circle cx="140" cy="179" r="2" fill="black" fillOpacity="0.05"/>
<path d="M145.95 68.759C145.054 68.759 144.347 68.4451 143.83 67.8172C143.312 67.1893 143.054 66.3583 143.054 65.3241C143.054 64.3269 143.157 63.4035 143.364 62.554C143.606 61.6676 143.968 60.7996 144.45 59.9501C144.967 59.0637 145.64 58.1773 146.467 57.2909C147.019 56.663 147.45 56.109 147.76 55.6288C148.105 55.1487 148.346 54.7054 148.484 54.2992C148.622 53.856 148.691 53.4127 148.691 52.9695C148.691 52.12 148.398 51.4737 147.812 51.0305C147.226 50.5873 146.381 50.3657 145.278 50.3657C144.174 50.3657 143.14 50.4949 142.175 50.7535C141.209 51.012 140.278 51.3998 139.382 51.9169C138.348 52.5078 137.434 52.711 136.641 52.5263C135.848 52.3047 135.227 51.8615 134.779 51.1967C134.331 50.5319 134.072 49.7562 134.003 48.8698C133.969 47.9834 134.193 47.1154 134.676 46.2659C135.193 45.4164 135.986 44.7147 137.055 44.1607C138.468 43.422 139.968 42.8864 141.554 42.554C143.14 42.1847 144.623 42 146.002 42C148.381 42 150.467 42.4063 152.259 43.2188C154.087 44.0314 155.5 45.1764 156.5 46.6537C157.5 48.0942 158 49.7932 158 51.7507C158 52.8587 157.845 53.9114 157.535 54.9086C157.224 55.9058 156.724 56.8846 156.035 57.8449C155.345 58.7682 154.38 59.7101 153.139 60.6704C152.07 61.5199 151.242 62.277 150.656 62.9418C150.07 63.5697 149.639 64.1791 149.363 64.7701C149.122 65.361 148.932 66.0074 148.794 66.7091C148.657 67.3001 148.363 67.7987 147.915 68.205C147.467 68.5743 146.812 68.759 145.95 68.759ZM145.795 82C144.209 82 142.95 81.5014 142.02 80.5042C141.123 79.5069 140.675 78.1958 140.675 76.5706C140.675 74.9825 141.123 73.7082 142.02 72.7479C142.95 71.7507 144.209 71.2521 145.795 71.2521C147.381 71.2521 148.622 71.7507 149.518 72.7479C150.415 73.7082 150.863 74.9825 150.863 76.5706C150.863 78.1958 150.415 79.5069 149.518 80.5042C148.622 81.5014 147.381 82 145.795 82Z" fill="url(#paint13_linear_329_5537)"/>
</g>
<path d="M0 0H220H0ZM220 221H0V219H220V221ZM0 220V0V220ZM220 0V220V0Z" fill="url(#paint14_linear_329_5537)" mask="url(#path-1-inside-1_329_5537)"/>
<defs>
<linearGradient id="paint0_linear_329_5537" x1="157.88" y1="230.879" x2="182.865" y2="205.894" gradientUnits="userSpaceOnUse">
<stop stop-color="#FE9800"/>
<stop offset="1" stop-color="#FE9800" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint1_linear_329_5537" x1="110.215" y1="135" x2="110.215" y2="127" gradientUnits="userSpaceOnUse">
<stop stop-color="#FE9800"/>
<stop offset="1" stop-color="#FE9800" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint2_linear_329_5537" x1="110" y1="135" x2="110" y2="127" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint3_linear_329_5537" x1="110.032" y1="69" x2="110.032" y2="59" gradientUnits="userSpaceOnUse">
<stop stop-color="#FE9800"/>
<stop offset="1" stop-color="#FE9800" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint4_linear_329_5537" x1="107" y1="64" x2="113" y2="64" gradientUnits="userSpaceOnUse">
<stop stop-opacity="0"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint5_linear_329_5537" x1="110.645" y1="127" x2="110.645" y2="67" gradientUnits="userSpaceOnUse">
<stop stop-color="#FE9800"/>
<stop offset="1" stop-color="#FE9800" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint6_linear_329_5537" x1="50" y1="97" x2="170" y2="97" gradientUnits="userSpaceOnUse">
<stop stop-opacity="0"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint7_linear_329_5537" x1="110.108" y1="60" x2="110.108" y2="40" gradientUnits="userSpaceOnUse">
<stop stop-color="#FE9800"/>
<stop offset="1" stop-color="#FE9800" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint8_linear_329_5537" x1="100" y1="50" x2="120" y2="50" gradientUnits="userSpaceOnUse">
<stop stop-opacity="0"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint9_linear_329_5537" x1="99.1022" y1="194" x2="99.1022" y2="135" gradientUnits="userSpaceOnUse">
<stop stop-color="#FE9800"/>
<stop offset="1" stop-color="#FE9800" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint10_linear_329_5537" x1="132" y1="135" x2="132" y2="194" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint11_linear_329_5537" x1="132.215" y1="212.284" x2="132.215" y2="196.284" gradientUnits="userSpaceOnUse">
<stop stop-color="#FE9800"/>
<stop offset="1" stop-color="#FE9800" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint12_linear_329_5537" x1="112" y1="204.284" x2="152" y2="204.284" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint13_linear_329_5537" x1="146" y1="42" x2="146" y2="82" gradientUnits="userSpaceOnUse">
<stop stop-color="#FE9800"/>
<stop offset="1" stop-color="#E88D04"/>
</linearGradient>
<linearGradient id="paint14_linear_329_5537" x1="220" y1="220" x2="2.563e-06" y2="220" gradientUnits="userSpaceOnUse">
<stop stop-color="#DADADA" stop-opacity="0"/>
<stop offset="0.5" stop-color="#DADADA"/>
<stop offset="1" stop-color="#DADADA" stop-opacity="0"/>
</linearGradient>
<clipPath id="clip0_329_5537">
<path d="M0 100C0 44.7715 44.7715 0 100 0H120C175.228 0 220 44.7715 220 100V220H0V100Z" fill="white"/>
</clipPath>
</defs>
</svg>`;

export default NoEmployees;
