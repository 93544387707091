import React, { useEffect, useState } from "react";
import useInput from "../../../../hooks/use-input";
import Select from "../../../../UI/Select";
import {
  CustomDropDownIcon,
  VacationCalendarIcon,
} from "../../../../icons/icons";
import classes from "./EditEmployeeDetails.module.css";
import { deleteUser, updateStaff, uploadImage } from "src/auth/api/requests";
import { toast } from "react-toastify";
import DragAndDrop from "../../../CreatePlace/Form/DragAndDrop";
import { components } from "react-select";
import SaveChangesButton from "./SaveChangesButton";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Employee } from "../../StaffTypes";
import CurrencyInput from "react-currency-input-field";
import Compressor from "compressorjs";
import TimeTable from "../../OverviewCalendar/TimeTable";

type EditEmployeeDetailsProps = {
  employee: Employee;
  onCloseSidebar: () => void;
  setEmployee: (updatedEmployee: Employee) => void;
  setNewFetch: (value) => void;
};

const EditEmployeeDetails: React.FC<EditEmployeeDetailsProps> = ({
  employee,
  setEmployee,
  onCloseSidebar,
  setNewFetch,
}) => {
  const [submitted, setSubmitted] = useState(false);
  const [isRequired, setIsRequired] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(employee.phoneNumber);
  const [valid, setValid] = useState(true);

  const handleChange = (value) => {
    setPhoneNumber(value);
    setValid(validatePhoneNumber(value));
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberPattern = /^\+?[1-9]\d{10,14}$/;

    return phoneNumberPattern.test(phoneNumber);
  };

  const isNotEmpty = (value: string) => value.trim() !== "";

  const {
    value: fullName,
    valueChangeHandler: fullNameChangedHandler,
    inputBlurHandler: fullNameBlurHandler,
  } = useInput(isNotEmpty, employee.username || "");

  const {
    value: email,
    valueChangeHandler: emailChangedHandler,
    inputBlurHandler: emailBlurHandler,
  } = useInput((value: string) => value.includes("@"), employee.email || "");

  const [selectedRole, setSelectedRole] = useState(employee.role || "");

  const [formChanged, setFormChanged] = useState(true);

  const handleRoleChange = (selectedOption: any) => {
    setSelectedRole(selectedOption.value);
  };
  const [showTimeTable, setShowTimeTable] = useState(false);

  // const handleButtonClick = () => {
  //   setShowTimeTable(true);
  // };
  const handleCloseSidebar = () => {
    setShowTimeTable(false);
  };
  const notify = () =>
    toast.success("Succes!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        {CustomDropDownIcon}
      </components.DropdownIndicator>
    );
  };

  const CustomSelect = (props: any) => {
    const customStyles = {
      dropdownIndicator: (provided: any) => ({
        ...provided,
        width: "40px",
        height: "40px",
      }),

      control: (provided: any, state: any) => ({
        ...provided,
        display: "flex",
        height: "52px",
        padding: "0px 8px",
        alignItems: "center",
        gap: "8px",
        alignSelf: "stretch",
        borderRadius: "12px",
        border: "1px solid #EEE !important",
        backgroundColor: "var(--brand-snow, #FFF)",
        boxShadow: "0 0 0 1px #EEE",

        "&:focus-within": {
          boxShadow: "0 0 0 1.5px rgba(254, 152, 0, 1)",
          outlineColor: "red",
        },
      }),

      container: (provided: any, state: any) => ({
        ...provided,
        marginTop: "12px",
      }),
    };
    return (
      <>
        <div className={classes.customSelect}>
          {props.label && (
            <p id={props.id} className={classes.label}>
              {props.label}
            </p>
          )}
          <Select
            name={props.name}
            styles={customStyles}
            onChange={props.onChange}
            onBlur={props.onBlur}
            value={props.options.find(
              (option: any) => option.value === props.value
            )}
            placeholder={props.placeholder}
            disabled={props.disabled}
            options={props.options}
            maxMenuHeight={props.maxMenuHeight}
            components={{ DropdownIndicator }}
            onInputChange={props.onInputChange}
          />
        </div>
      </>
    );
  };

  const employeeRoles = [
    { value: "SUPER_HOSTESS", label: "Head Hostess" },
    { value: "WAITER", label: "Waiter" },
    { value: "CHEF", label: "Chef" },
    { value: "HOSTESS", label: "Hostess" },
    { value: "OPERATOR", label: "Operator" },
    { value: "SPECIALIST", label: "Specialist" },
    { value: "DRIVER", label: "Driver" },
  ];

  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [compressedFile, setCompressedFile] = useState(null);

  const handleFileUpload = (file) => {
    setUploadedFile(file);
  };

  interface EmployeeSubmit {
    username?: any;
    email?: any;
    phoneNumber?: any;
    role?: any;
    salary?: any;
  }
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      if (uploadedFile) {
        new Compressor(uploadedFile, {
          quality: 0.6,
          success: async (compressedResult) => {
            const formData = new FormData();
            formData.append("file", compressedResult, uploadedFile?.name);
            const imageKey = await uploadImage(formData);

            // Use the compressed file to upload the images to your server.
            setCompressedFile(imageKey);
            if (formIsValid) {
              const updatedEmployee = {
                avatar: imageKey !== null ? imageKey : employee.avatar,
              };
              const updatedEmployeeData = await updateStaff(
                employee.id,
                updatedEmployee
              );
              notify();
              setEmployee(updatedEmployeeData);
              setSubmitted(true);
            }
          },
        });
      }
      if (formIsValid) {
        const updatedEmployee: EmployeeSubmit = {};

        if (fullName !== employee.username) {
          updatedEmployee.username = fullName;
        }
        if (email !== employee.email) {
          updatedEmployee.email = email;
        }
        if (phoneNumber !== employee.phoneNumber) {
          updatedEmployee.phoneNumber = `+${phoneNumber}`;
        }
        if (selectedRole !== employee.role) {
          updatedEmployee.role = selectedRole;
        }
        if (Number(salary) !== employee.salary && Number(salary) !== 0) {
          updatedEmployee.salary = Number(salary);
        }
        const updatedEmployeeData = await updateStaff(
          employee.id,
          updatedEmployee
        );
        notify();
        setEmployee(updatedEmployeeData);
        setSubmitted(true);
      }

      // console.log(compressedFile);
    } catch (error) {
      console.error("Error updated employee:", error);
    }
  };

  const [salary, setSalary] = useState<string | undefined>(
    `${employee.salary === null ? "" : employee.salary}`
  );

  const handleOnValueChange = (salary: string | undefined): void => {
    setSalary(salary);
  };

  useEffect(() => {
    const hasFormChanged =
      fullName !== employee.username ||
      email !== employee.email ||
      phoneNumber !== employee.phoneNumber ||
      selectedRole !== employee.role ||
      uploadedFile !== null ||
      (Number(salary) !== employee.salary && Number(salary) !== 0);

    setFormChanged(hasFormChanged);
  }, [
    fullName,
    email,
    phoneNumber,
    selectedRole,
    employee,
    uploadedFile,
    salary,
  ]);

  const formIsValid =
    formChanged &&
    ((fullName && employee.username !== "") ||
      (email && employee.email !== "") ||
      (phoneNumber && employee.phoneNumber !== "")) &&
    // ||      (salary !== "" && employee.salary.toString() !== "")
    valid === true &&
    selectedRole !== "" &&
    !submitted;

  const preferedCountries = ["md", "ro", "ae", "il", "it", "si"];

  const succes = () =>
    toast.success("Deleted!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

  const eroare = (text) =>
    toast.error(text, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

  const handleDeleteUser = async (employeeId) => {
    try {
      await deleteUser(employeeId);
      succes();
      setNewFetch((prevState) => !prevState);
      onCloseSidebar();
    } catch (error) {
      eroare(error);
    }
  };

  return showTimeTable ? (
    <TimeTable
      onBack={handleCloseSidebar}
      onClose={handleCloseSidebar}
      employee={employee}
    />
  ) : (
    <form className={classes.BoxForm} onSubmit={handleSubmit}>
      <div className={classes.BoxFormDrag}>
        <DragAndDrop
          onFileUpload={handleFileUpload}
          background={
            employee.avatar?.startsWith("avatar_")
              ? require(`../../../../assets/${employee?.avatar}`).default
              : employee?.avatar !== null &&
                !employee?.avatar?.startsWith("avatar_")
              ? employee?.avatarUrl
              : null
          }
          isRequired={isRequired}
        />
      </div>
      <div className={classes.BoxFormRow}>
        <div className={classes.BoxFormInputContainer}>
          <label htmlFor="name" className={classes.label}>
            Full name
          </label>
          <input
            className={classes.BoxFormInput}
            type="text"
            id="name"
            placeholder="Enter employee's name and surname"
            onChange={fullNameChangedHandler}
            onBlur={fullNameBlurHandler}
            defaultValue={employee.username}
          />
        </div>
        <div className={classes.BoxFormSelect}>
          <CustomSelect
            labelId="role-label"
            value={selectedRole}
            onChange={handleRoleChange}
            label="Role"
            variant="outlined"
            options={employeeRoles}
            placeholder="Select the role"
            selectClassName={classes.customSelect}
          />
        </div>
        <div className={classes.BoxFormInputContainerSalary}>
          <label htmlFor="name" className={classes.label}>
            Salary
          </label>
          <CurrencyInput
            className={classes.BoxFormInput}
            type="text"
            id="salary"
            placeholder="Enter employee's salary"
            onValueChange={handleOnValueChange}
            value={salary !== null ? salary : 0}
            // defaultValue={employee.salary}
            suffix={salary === null ? "" : " lei"}
          />
        </div>
      </div>
      <div className={classes.BoxFormRow}>
        <div className={classes.BoxFormInputContainer}>
          <label htmlFor="email" className={classes.label}>
            E-mail address
          </label>
          <input
            className={classes.BoxFormInput}
            type="email"
            id="email"
            placeholder="Enter e-mail address"
            onChange={emailChangedHandler}
            onBlur={emailBlurHandler}
            defaultValue={employee.email}
          />
        </div>
        <div className={classes.BoxFormInputContainerPhone}>
          <label htmlFor="phone" className={classes.label}>
            Phone number
          </label>
          <PhoneInput
            country={"md"}
            value={phoneNumber}
            onChange={handleChange}
            countryCodeEditable={false}
            preferredCountries={preferedCountries}
            placeholder="Enter phone number"
            containerClass={classes.PhoneContainer}
            inputClass={classes.PhoneInput}
            dropdownClass={classes.PhoneDropDown}
            buttonClass={classes.PhoneButton}
            inputProps={{
              required: true,
            }}
          />
        </div>
        <button
          className={classes.TimeTable}
          onClick={() => setShowTimeTable(true)}
        >
          <span className={classes.TimeTableText}>View the timetable</span>
        </button>
      </div>
      <button
        className={classes.DeleteUserButton}
        onClick={() => handleDeleteUser(employee.id)}
      >
        Delete employee
      </button>
      <SaveChangesButton formIsValid={formIsValid} />
    </form>
  );
};

export default EditEmployeeDetails;
