import React from "react";

export const NoStockImage = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="220"
    height="220"
    viewBox="0 0 220 220"
    fill="none"
  >
    <g clip-path="url(#clip0_4454_15015)">
      <mask id="path-1-inside-1_4454_15015" fill="white">
        <path d="M0 100C0 44.7715 44.7715 0 100 0H120C175.228 0 220 44.7715 220 100V220H0V100Z" />
      </mask>
      <path
        d="M0 100C0 44.7715 44.7715 0 100 0H120C175.228 0 220 44.7715 220 100V220H0V100Z"
        fill="#FAFAFA"
      />
      <path
        d="M109.95 62.759C109.054 62.759 108.347 62.4451 107.83 61.8172C107.312 61.1893 107.054 60.3583 107.054 59.3241C107.054 58.3269 107.157 57.4035 107.364 56.554C107.606 55.6676 107.968 54.7996 108.45 53.9501C108.967 53.0637 109.64 52.1773 110.467 51.2909C111.019 50.663 111.45 50.109 111.76 49.6288C112.105 49.1487 112.346 48.7054 112.484 48.2992C112.622 47.856 112.691 47.4127 112.691 46.9695C112.691 46.12 112.398 45.4737 111.812 45.0305C111.226 44.5873 110.381 44.3657 109.278 44.3657C108.174 44.3657 107.14 44.4949 106.175 44.7535C105.209 45.012 104.278 45.3998 103.382 45.9169C102.348 46.5078 101.434 46.711 100.641 46.5263C99.848 46.3047 99.2274 45.8615 98.7792 45.1967C98.331 44.5319 98.0724 43.7562 98.0034 42.8698C97.969 41.9834 98.1931 41.1154 98.6758 40.2659C99.1929 39.4164 99.9859 38.7147 101.055 38.1607C102.468 37.422 103.968 36.8864 105.554 36.554C107.14 36.1847 108.623 36 110.002 36C112.381 36 114.467 36.4063 116.259 37.2188C118.087 38.0314 119.5 39.1764 120.5 40.6537C121.5 42.0942 122 43.7932 122 45.7507C122 46.8587 121.845 47.9114 121.535 48.9086C121.224 49.9058 120.724 50.8846 120.035 51.8449C119.345 52.7682 118.38 53.7101 117.139 54.6704C116.07 55.5199 115.242 56.277 114.656 56.9418C114.07 57.5697 113.639 58.1791 113.363 58.7701C113.122 59.361 112.932 60.0074 112.794 60.7091C112.657 61.3001 112.363 61.7987 111.915 62.205C111.467 62.5743 110.812 62.759 109.95 62.759ZM109.795 76C108.209 76 106.95 75.5014 106.02 74.5042C105.123 73.5069 104.675 72.1958 104.675 70.5706C104.675 68.9825 105.123 67.7082 106.02 66.7479C106.95 65.7507 108.209 65.2521 109.795 65.2521C111.381 65.2521 112.622 65.7507 113.518 66.7479C114.415 67.7082 114.863 68.9825 114.863 70.5706C114.863 72.1958 114.415 73.5069 113.518 74.5042C112.622 75.5014 111.381 76 109.795 76Z"
        fill="url(#paint0_linear_4454_15015)"
      />
      <path
        d="M49.7647 141.457H128.824V220.002H53.7647C51.5556 220.002 49.7647 218.211 49.7647 216.002V141.457Z"
        fill="#F0F0F0"
      />
      <path
        d="M49.7647 141.457H128.824V220.002H53.7647C51.5556 220.002 49.7647 218.211 49.7647 216.002V141.457Z"
        fill="url(#paint1_linear_4454_15015)"
        fill-opacity="0.05"
      />
      <path
        d="M49.7647 141.457L128.824 148.002V141.457H49.7647Z"
        fill="#F0F0F0"
      />
      <path
        d="M49.7647 141.457L128.824 148.002V141.457H49.7647Z"
        fill="url(#paint2_linear_4454_15015)"
        fill-opacity="0.05"
      />
      <path
        d="M49.7647 141.457L128.824 148.002V141.457H49.7647Z"
        fill="black"
        fill-opacity="0.1"
      />
      <path
        d="M82.7059 181.327C82.7059 181.105 82.9743 180.993 83.1321 181.15L83.6481 181.663C84.0381 182.05 84.6677 182.05 85.0577 181.663L85.2952 181.427C85.6852 181.039 86.3148 181.039 86.7048 181.427L86.9423 181.663C87.3322 182.05 87.9619 182.05 88.3518 181.663L88.5893 181.427C88.9793 181.039 89.6089 181.039 89.9989 181.427L90.2364 181.663C90.6264 182.05 91.256 182.05 91.646 181.663L91.8834 181.427C92.2734 181.039 92.903 181.039 93.293 181.427L93.5305 181.663C93.9205 182.05 94.5501 182.05 94.9401 181.663L95.4561 181.15C95.6139 180.993 95.8823 181.105 95.8823 181.327V219.999H82.7059V181.327Z"
        fill="#F0F0F0"
      />
      <path
        d="M82.7059 181.327C82.7059 181.105 82.9743 180.993 83.1321 181.15L83.6481 181.663C84.0381 182.05 84.6677 182.05 85.0577 181.663L85.2952 181.427C85.6852 181.039 86.3148 181.039 86.7048 181.427L86.9423 181.663C87.3322 182.05 87.9619 182.05 88.3518 181.663L88.5893 181.427C88.9793 181.039 89.6089 181.039 89.9989 181.427L90.2364 181.663C90.6264 182.05 91.256 182.05 91.646 181.663L91.8834 181.427C92.2734 181.039 92.903 181.039 93.293 181.427L93.5305 181.663C93.9205 182.05 94.5501 182.05 94.9401 181.663L95.4561 181.15C95.6139 180.993 95.8823 181.105 95.8823 181.327V219.999H82.7059V181.327Z"
        fill="url(#paint3_linear_4454_15015)"
        fill-opacity="0.05"
      />
      <path
        d="M82.7059 181.327C82.7059 181.105 82.9743 180.993 83.1321 181.15L83.6481 181.663C84.0381 182.05 84.6677 182.05 85.0577 181.663L85.2952 181.427C85.6852 181.039 86.3148 181.039 86.7048 181.427L86.9423 181.663C87.3322 182.05 87.9619 182.05 88.3518 181.663L88.5893 181.427C88.9793 181.039 89.6089 181.039 89.9989 181.427L90.2364 181.663C90.6264 182.05 91.256 182.05 91.646 181.663L91.8834 181.427C92.2734 181.039 92.903 181.039 93.293 181.427L93.5305 181.663C93.9205 182.05 94.5501 182.05 94.9401 181.663L95.4561 181.15C95.6139 180.993 95.8823 181.105 95.8823 181.327V219.999H82.7059V181.327Z"
        fill="url(#paint4_linear_4454_15015)"
        fill-opacity="0.1"
      />
      <path
        d="M82.7059 181.327C82.7059 181.105 82.9743 180.993 83.1321 181.15L83.6481 181.663C84.0381 182.05 84.6677 182.05 85.0577 181.663L85.2952 181.427C85.6852 181.039 86.3148 181.039 86.7048 181.427L86.9423 181.663C87.3322 182.05 87.9619 182.05 88.3518 181.663L88.5893 181.427C88.9793 181.039 89.6089 181.039 89.9989 181.427L90.2364 181.663C90.6264 182.05 91.256 182.05 91.646 181.663L91.8834 181.427C92.2734 181.039 92.903 181.039 93.293 181.427L93.5305 181.663C93.9205 182.05 94.5501 182.05 94.9401 181.663L95.4561 181.15C95.6139 180.993 95.8823 181.105 95.8823 181.327V219.999H82.7059V181.327Z"
        fill="black"
        fill-opacity="0.1"
      />
      <rect
        x="128.824"
        y="141.453"
        width="49.4118"
        height="78.545"
        fill="#F0F0F0"
      />
      <rect
        x="128.824"
        y="141.453"
        width="49.4118"
        height="78.545"
        fill="url(#paint5_linear_4454_15015)"
        fill-opacity="0.05"
      />
      <rect
        x="128.824"
        y="141.453"
        width="49.4118"
        height="78.545"
        fill="url(#paint6_linear_4454_15015)"
        fill-opacity="0.1"
      />
      <rect
        x="128.824"
        y="141.453"
        width="49.4118"
        height="78.545"
        fill="black"
        fill-opacity="0.1"
      />
      <path
        d="M34.1797 118.229C32.3965 115.571 34.301 112 37.5012 112H105.859C107.859 112 109.727 112.996 110.842 114.657L128.824 141.454H49.7647L34.1797 118.229Z"
        fill="#F0F0F0"
      />
      <path
        d="M34.1797 118.229C32.3965 115.571 34.301 112 37.5012 112H105.859C107.859 112 109.727 112.996 110.842 114.657L128.824 141.454H49.7647L34.1797 118.229Z"
        fill="url(#paint7_linear_4454_15015)"
        fill-opacity="0.05"
      />
      <path
        d="M34.1797 118.229C32.3965 115.571 34.301 112 37.5012 112H105.859C107.859 112 109.727 112.996 110.842 114.657L128.824 141.454H49.7647L34.1797 118.229Z"
        fill="black"
        fill-opacity="0.1"
      />
      <path
        d="M193.82 118.229C195.603 115.571 193.699 112 190.499 112H151.788C149.788 112 147.92 112.996 146.805 114.657L128.824 141.454H178.235L193.82 118.229Z"
        fill="#F0F0F0"
      />
      <path
        d="M193.82 118.229C195.603 115.571 193.699 112 190.499 112H151.788C149.788 112 147.92 112.996 146.805 114.657L128.824 141.454H178.235L193.82 118.229Z"
        fill="url(#paint8_linear_4454_15015)"
        fill-opacity="0.05"
      />
      <path
        d="M193.82 118.229C195.603 115.571 193.699 112 190.499 112H151.788C149.788 112 147.92 112.996 146.805 114.657L128.824 141.454H178.235L193.82 118.229Z"
        fill="url(#paint9_linear_4454_15015)"
        fill-opacity="0.1"
      />
      <path
        d="M193.82 118.229C195.603 115.571 193.699 112 190.499 112H151.788C149.788 112 147.92 112.996 146.805 114.657L128.824 141.454H178.235L193.82 118.229Z"
        fill="black"
        fill-opacity="0.1"
      />
    </g>
    <path
      d="M0 0H220H0ZM220 221H0V219H220V221ZM0 220V0V220ZM220 0V220V0Z"
      fill="url(#paint10_linear_4454_15015)"
      mask="url(#path-1-inside-1_4454_15015)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4454_15015"
        x1="110"
        y1="36"
        x2="110"
        y2="76"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FE9800" />
        <stop offset="1" stop-color="#E88D04" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4454_15015"
        x1="89.7192"
        y1="220.002"
        x2="89.7192"
        y2="141.457"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FE9800" />
        <stop offset="1" stop-color="#FE9800" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_4454_15015"
        x1="89.7192"
        y1="148.002"
        x2="89.7192"
        y2="141.457"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FE9800" />
        <stop offset="1" stop-color="#FE9800" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_4454_15015"
        x1="89.3649"
        y1="219.999"
        x2="89.3649"
        y2="180.727"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FE9800" />
        <stop offset="1" stop-color="#FE9800" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_4454_15015"
        x1="82.7059"
        y1="200.363"
        x2="95.8823"
        y2="200.363"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-opacity="0" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_4454_15015"
        x1="153.795"
        y1="219.998"
        x2="153.795"
        y2="111.999"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FE9800" />
        <stop offset="1" stop-color="#FE9800" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_4454_15015"
        x1="128.824"
        y1="180.726"
        x2="178.235"
        y2="180.726"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-opacity="0" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_4454_15015"
        x1="79.9431"
        y1="141.454"
        x2="79.9431"
        y2="112"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FE9800" />
        <stop offset="1" stop-color="#FE9800" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_4454_15015"
        x1="163.04"
        y1="141.454"
        x2="163.04"
        y2="112"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FE9800" />
        <stop offset="1" stop-color="#FE9800" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_4454_15015"
        x1="198"
        y1="126.727"
        x2="128.824"
        y2="126.727"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-opacity="0" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_4454_15015"
        x1="220"
        y1="220"
        x2="2.563e-06"
        y2="220"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#DADADA" stop-opacity="0" />
        <stop offset="0.5" stop-color="#DADADA" />
        <stop offset="1" stop-color="#DADADA" stop-opacity="0" />
      </linearGradient>
      <clipPath id="clip0_4454_15015">
        <path
          d="M0 100C0 44.7715 44.7715 0 100 0H120C175.228 0 220 44.7715 220 100V220H0V100Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);
