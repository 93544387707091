import React, { useState } from "react";
import classes from "./MealSetup.module.css";
import Title from "../Title";
import Input from "./../../UI/Input";
import useInput from "../../hooks/use-input";
import { useSelector } from "react-redux";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Type from "./Type";
import Ingredients from "./Ingredients";
import { createProduct, editProduct, uploadImage } from "src/auth/api/requests";
import DragAndDrop from "../CreatePlace/Form/DragAndDrop";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { TextareaAutosize } from "@mui/material";
import FoodCategory from "./FoodCategory";

interface Ingredient {
  label: string;
  count: number;
  unit?: string;
  name: string;
}

interface Category {
  name: string;
}
interface FoodCategory {
  name: string;
}

type MealSetupProps = {};

const MealSetup: React.FC<MealSetupProps> = () => {
  const location = useLocation();
  const [isRequired, setIsRequired] = useState(false);

  const initialTitle = String(location.state?.title || "");
  const initialPrice = String(location.state?.price || "");
  const initialAlergens = String(location.state?.allergens || "");
  const initialPreparationTime = String(location.state?.preparationTime || "");
  const initialCategory = location.state?.category || { name: "" };
  const initialFoodCategory = location.state?.preparationZone || "";
  const initialIngredients = location.state?.ingredients || [];
  const isExistingProduct = location.state?.isExistingProduct;
  const initialImage = location.state?.image || null;
  const initialComments = String(location.state?.recipe || "");

  const isNotEmpty = (value: string) => value && value.trim() !== "";
  // const [price, setPrice] = useState<number>(0);
  const {
    value: enteredTitle,
    hasError: placeNameInputHasError,
    isValid: enteredPlaceNameIsValid,
    valueChangeHandler: placeNameChangedHandler,
    inputBlurHandler: placeNameBlurHandler,
    reset: resetPlaceNameInput,
  } = useInput(isNotEmpty, initialTitle);

  const {
    value: enteredPrice,
    hasError: priceInputHasError,
    isValid: enteredPriceIsValid,
    valueChangeHandler: priceChangedHandler,
    inputBlurHandler: priceBlurHandler,
    reset: resetPriceInput,
  } = useInput(isNotEmpty, initialPrice);
  const {
    value: enteredAlergens,
    hasError: alergensInputHasError,
    isValid: enteredAlergensIsValid,
    valueChangeHandler: alergensChangedHandler,
    inputBlurHandler: alergensBlurHandler,
    reset: resetAlergensInput,
  } = useInput(isNotEmpty, initialAlergens);
  const {
    value: enteredPreparationTime,
    hasError: preparationTimeInputHasError,
    isValid: enteredPreaparationTimeIsValid,
    valueChangeHandler: preparationTimeChangedHandler,
    inputBlurHandler: preparationTimeBlurHandler,
    reset: resetPreparationTimeInput,
  } = useInput(isNotEmpty, initialPreparationTime);
  const {
    value: enteredComments,
    hasError: commentsInputHasError,
    valueChangeHandler: commentsChangedHandler,
    inputBlurHandler: commentsBlurHandler,
    reset: resetCommentsInput,
  } = useInput(isNotEmpty, initialComments);
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    initialCategory
  );
  const [selectedFoodCategory, setSelectedFoodCategory] =
  useState<string>(initialFoodCategory);
  const [selectedIngredients, setSelectedIngredients] =
  useState<Ingredient[]>(initialIngredients);

  const [totalWeight, setTotalWeight] = useState<number>(0);
  const handleIngredientsChange = (ingredients: any[]) => {
    setSelectedIngredients(ingredients);
  };
  const [edittedTotalWeight, setEdittedTotalWeight] = useState<number>(0);
  const handleTotalWeightChange = (weight: number) => {
    setTotalWeight(weight);
  };
  const handleEdiitedTotalWeightChange = (weight: number) => {
    setEdittedTotalWeight(weight);
  };
  const handleTypeSelect = (item: Category) => {
    setSelectedCategory(item);
  };
  const handleFoodCategorySelect = (item: string) => {
    setSelectedFoodCategory(item);
  };
  const enteredAvatar = useSelector((state: any) => state.formData.file);
  let formIsValid = false;
  if (
    enteredPlaceNameIsValid &&
    selectedCategory?.name.length !== 0 &&
    selectedCategory?.name !== undefined &&
    enteredPreaparationTimeIsValid  &&
    enteredAlergensIsValid &&
    enteredPriceIsValid
    //  &&    enteredAvatar.name !== undefined
  ) {
    formIsValid = true;
  }

  const success = () =>
    toast.success("Succes!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

  const [productCreated, setProductCreated] = useState(false);
  const existingImage = initialImage?.split("/").pop() || "";
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const ingredients = selectedIngredients.map((ingredient) => ({
      name: ingredient.label,
      quantity:
        ingredient.unit === "kg"
          ? ingredient.count * 1000
          : ingredient.unit === "ounces"
          ? ingredient.count * 28.3495
          : ingredient.count,
    }));

    const formData = new FormData();

    try {
      const storedRestaurant = JSON.parse(
        localStorage.getItem("selectedRestaurant") ?? "null"
      );

      let imageKey = null;
      if (uploadedFile) {
        formData.append("file", uploadedFile, uploadedFile?.name);
        imageKey = await uploadImage(formData);
      }
      const productData = {
        title: enteredTitle,//
        price: enteredPrice,//
        allergens: enteredAlergens,
        recipe: enteredComments,
        preparationTime: enteredPreparationTime,
        weight: totalWeight.toString(),//
        image: imageKey ? imageKey : null,//
        category: selectedCategory,//
        preparationZone: selectedFoodCategory,
        ingredients,//
      };

      const edittedProductData = {
        title: enteredTitle,
        price: enteredPrice,
        allergens: enteredAlergens,
        recipe: enteredComments,
        preparationTime: enteredPreparationTime,
        weight: edittedTotalWeight.toString(),
        image:
          imageKey !== null
            ? imageKey
            : existingImage.length > 0
            ? existingImage
            : null,
        category: selectedCategory,
        preparationZone: selectedFoodCategory,
        ingredients: edittedIngredientsArray,
      };

      if (isExistingProduct) {
        await editProduct(
          edittedProductData,
          location.state?.id ? location.state?.id : "null"
        );
        setProductCreated(true);
      } else {
        await createProduct(productData, storedRestaurant.id);
        setProductCreated(true);
      }
      success();
      resetPriceInput();
    } catch (error) {
      console.error("Error creating product:", error);
      // Handle error here
    }
  };

  const transformToNewFormat = (selectedIngredients) => {
    return selectedIngredients.map((ingredient) => {
      if (ingredient.count !== undefined) {
        return {
          name: ingredient?.label,
          quantity: ingredient?.count,
        };
      } else if (ingredient.ingredient) {
        return {
          name: ingredient.ingredient.name,
          quantity: ingredient.quantity,
        };
      }
      // Handle other cases or return a default value if needed
      return {};
    });
  };

  const edittedIngredientsArray = transformToNewFormat(selectedIngredients);

  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const handleFileUpload = (file) => {
    setUploadedFile(file);
  };

  const navigate = useNavigate();

  const handleBackButtonClick = () => {
    navigate("/menu");
  };

  if (productCreated) {
    handleBackButtonClick();
  }
  return (
    <div>
      <Title
        title={isExistingProduct ? "EditMeal" : "Create new meal"}
        subtitle={""}
      />
      <form className={classes.MealForm} onSubmit={() => {}}>
        <Row className={classes.form_inputs}>
          <Col xs={12} lg={8}>
            <Input
              label="Title"
              placeholder="Enter meal title"
              value={enteredTitle}
              hasError={placeNameInputHasError}
              errorMessage="Value must not be empty"
              type="text"
              onChange={placeNameChangedHandler}
              onBlur={placeNameBlurHandler}
            />
          </Col>
          <Col>
            <Input
              label="Price"
              placeholder="Enter price"
              value={enteredPrice}
              hasError={priceInputHasError}
              errorMessage="Value must not be empty"
              type="number"
              onChange={priceChangedHandler}
              onBlur={priceBlurHandler}
            />
          </Col>
        </Row>
        <Type
          onTypeSelect={handleTypeSelect}
          restaurantId={
            JSON.parse(localStorage.getItem("selectedRestaurant") ?? "null").id
          }
          category={selectedCategory}
        />
        <FoodCategory
          onFoodCategorySelect={handleFoodCategorySelect}
          restaurantId={
            JSON.parse(localStorage.getItem("selectedRestaurant") ?? "null").id
          }
          category={selectedFoodCategory}
        />
        <Ingredients
          onIngredientsChange={handleIngredientsChange}
          onWeightChange={handleTotalWeightChange}
          onEdittedWeightChange={handleEdiitedTotalWeightChange}
          ingredientsFromBack={initialIngredients}
          isExistingProduct={isExistingProduct || false}
        />
        {/* <Galery createMealBtnClickHandler={handleSubmit} /> */}
        <Col>
          <Input
            label="Alergens"
            placeholder="Enter alergens"
            value={enteredAlergens}
            hasError={alergensInputHasError}
            errorMessage="Value must not be empty"
            type="text"
            onChange={alergensChangedHandler}
            onBlur={alergensBlurHandler}
          />
        </Col>
        <Col>
          <Input
            label="Preparation Time (minutes)"
            placeholder="Enter preparation time"
            value={enteredPreparationTime}
            hasError={preparationTimeInputHasError}
            errorMessage="Value must not be empty"
            type="number"
            onChange={preparationTimeChangedHandler}
            onBlur={preparationTimeBlurHandler}
          />
        </Col>
        <Col>
          <label htmlFor="comments" style={{marginBottom:"5px"}}>Recipe</label>
          <TextareaAutosize
            id="comments"
            minRows={4}
            className={classes.CommentsInput}
            placeholder="Enter recipe"
            value={enteredComments}
            onChange={commentsChangedHandler}
            onBlur={commentsBlurHandler}
          />
          {/* {commentsInputHasError && (
            <p
              style={{
                color: "red",
                opacity: "0.4",
                fontSize: "12px",
              }}
            >
              Value must not be empty
            </p>
          )} */}
        </Col>
        {/* <div className={classes.AlergensContainer}>
          <span className={classes.AlergensTitle}>Alergens</span>
          <input
            className={classes.AlergensInput}
            placeholder="Enter alergens"
          ></input>
        </div> */}
        <div className={classes.Gallery}>
          <span className={classes.GalleryTitle}>Images gallery</span>
          <DragAndDrop
            onFileUpload={handleFileUpload}
            parentComponent="meal"
            background={initialImage}
            isRequired={isRequired}
          />
        </div>
      </form>
      <div className={classes.FormActions}>
        <button
          className={classes.CreateMealButton}
          onClick={(e) => handleSubmit(e)}
          disabled={!formIsValid}
        >
          <span className={classes.CreateMealText}>
            {isExistingProduct ? "Save changes" : "Create meal"}
          </span>
        </button>
        <button className={classes.BackButton} onClick={handleBackButtonClick}>
          <span className={classes.BackButtonText}>Back</span>
        </button>
      </div>
    </div>
  );
};
export default MealSetup;
