import dayjs from "dayjs";
import moment from "moment";
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  closeIcon,
  dotIcon,
  FloorIcon,
  spreadIcon,
  sunIconUri,
  healthIconUri,
  restIconUri,
  VacationCalendarIcon,
  arrowRightIcon,
} from "../../../icons/icons";
import OutsideClickHandler from "../components/OutsideClickHandler";
import CalendarEmployeeDetails from "./CalendarEmployeeDetails";
import classes from "./CalendarModal.module.css";
import TimeTable from "./TimeTable";

type Props = {
  onClose: () => void;
  clickedDay: Date;
  dayType: string;
  employeesWithVacations: any[];
  employeesWithSchedules: any[];
};
const CalendarModal = (props: Props) => {
  const employeeBoxRef = useRef<HTMLDivElement | null>(null);
  const DEFAULT_IMAGE = "/staffImages/barmen.png";

  const [selectedEmployee, setSelectedEmployee] = useState<any>(null);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });

  const handleToggleSpreadButton = (
    employee: any,
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation(); // Prevent the event from propagating to the outer container

    const buttonRect = e.currentTarget.getBoundingClientRect();
    const modalWidth = 80;

    setModalPosition({
      top: buttonRect.top + buttonRect.height + 10, // 10px below the button's center
      left: buttonRect.right - modalWidth, // Right upper corner of the button
    });

    setSelectedEmployee((prevState) =>
      prevState === employee ? null : employee
    );
  };

  const [activeTab, setActiveTab] = useState("");

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  const clickedDay = props.clickedDay;

  const findFloorForDay = (employee) => {
    const scheduleForDay = employee.staffSchedules.find(
      (schedule) =>
        moment(schedule.date).isSame(moment(clickedDay)) && schedule.floor
    );

    return scheduleForDay ? scheduleForDay.floor : null;
  };

  return (
    <div className={classes.EditEmployeeModal}>
      {activeTab === "details" ? (
        <CalendarEmployeeDetails
          employee={selectedEmployee}
          onBack={handleTabClick}
          onClose={props.onClose}
        />
      ) : activeTab === "time" ? (
        <TimeTable
          employee={selectedEmployee}
          onBack={handleTabClick}
          onClose={props.onClose}
        />
      ) : (
        <div ref={employeeBoxRef} className={classes.EditEmployeeBox}>
          <OutsideClickHandler
            innerRef={employeeBoxRef}
            onClose={props.onClose}
          />
          <div className={classes.BoxHead}>
            <p className={classes.BoxHeadTitle}>
              {props.dayType === "WORK" ? "Staff on " : "Vacations on "}
              <span className={classes.BoxHeadDate}>
                {dayjs(props.clickedDay).format("D MMMM, YYYY")}
              </span>
            </p>
            <div className={classes.BoxHeadButtonContainer}>
              <button className={classes.BoxHeadButton} onClick={props.onClose}>
                {closeIcon}
              </button>
            </div>
          </div>
          <div className={classes.BoxBody}>
            <div className={classes.BoxList}>
              {props.dayType === "WORK"
                ? props.employeesWithSchedules.map((employee) => (
                    <div key={employee.id} className={classes.EmployeeRow}>
                      <div className={classes.EmployeeNameContainer}>
                        <div
                          className={classes.EmployeeAvatarContainer}
                          style={{
                            background: employee.avatar
                              ? `url(${employee.avatarUrl}), lightgray 50% / cover no-repeat`
                              : `url(${DEFAULT_IMAGE})`,
                            backgroundSize: "56px 56px",
                          }}
                        >
                          <span className={classes.WorkDot}>{dotIcon}</span>
                        </div>
                        <div className={classes.EmployeeTitleContainer}>
                          <p
                            className={classes.EmployeeName}
                            style={{ marginBottom: 0 }}
                          >
                            {employee.username}
                          </p>
                          <div className={classes.EmployeeSpace}>
                            <span className={classes.EmployeeSpaceIcon}>
                              {FloorIcon}
                            </span>
                            <span className={classes.EmployeeSpaceName}>
                              {findFloorForDay(employee)}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className={classes.EmployeeActionsContainer}>
                        <button
                          className={classes.EmployeeActionButton}
                          onClick={(e) => handleToggleSpreadButton(employee, e)}
                        >
                          <span className={classes.EmployeeActionIcon}>
                            {spreadIcon}
                          </span>
                        </button>
                        {selectedEmployee === employee && (
                          <div
                            className={classes.ButtonModal}
                            style={{
                              position: "fixed",
                              top: modalPosition.top + "px",
                              left: modalPosition.left + "px",
                              zIndex: 999,
                            }}
                          >
                            <button
                              className={classes.ModalButton}
                              onClick={() => handleTabClick("details")}
                            >
                              Details
                            </button>
                            <button
                              className={classes.ModalButton}
                              onClick={() => handleTabClick("time")}
                            >
                              TimeTable
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  ))
                : props.employeesWithVacations.map((employee) => (
                    <div key={employee.id} className={classes.EmployeeRow}>
                      <div className={classes.EmployeeNameContainer}>
                        <div
                          className={classes.EmployeeAvatarContainer}
                          style={{
                            background: employee.avatar
                              ? `url(${employee.avatarUrl}), lightgray 50% / cover no-repeat`
                              : `url(${DEFAULT_IMAGE})`,
                            backgroundSize: "56px 56px",
                          }}
                        ></div>
                        <div className={classes.EmployeeTitleContainer}>
                          <p
                            className={classes.EmployeeName}
                            style={{ marginBottom: 0 }}
                          >
                            {employee.username}
                          </p>
                          <div className={classes.EmployeeSpace}>
                            <span className={classes.EmployeeSpaceIcon}>
                              {employee.vacations.find(
                                (vacation) =>
                                  moment(props.clickedDay).isSameOrAfter(
                                    vacation.startDate,
                                    "day"
                                  ) &&
                                  moment(props.clickedDay).isSameOrBefore(
                                    vacation.endDate,
                                    "day"
                                  )
                              ).vacationType === "SIMPLE_VACATION" ? (
                                <img src={sunIconUri} alt="Sun Icon" />
                              ) : employee.vacations.find(
                                  (vacation) =>
                                    moment(props.clickedDay).isSameOrAfter(
                                      vacation.startDate,
                                      "day"
                                    ) &&
                                    moment(props.clickedDay).isSameOrBefore(
                                      vacation.endDate,
                                      "day"
                                    )
                                ).vacationType === "SICK_VACATION" ? (
                                <img src={healthIconUri} alt="Health Icon" />
                              ) : (
                                <img src={restIconUri} alt="Rest Icon" />
                              )}
                            </span>
                            <div className={classes.EmployeeSpaceName}>
                              <span className={classes.VacationTitle}>
                                Vacation{" "}
                              </span>
                            </div>
                            <span className={classes.VacationIdentifier}>
                              {
                                employee.vacations.find(
                                  (vacation) =>
                                    moment(props.clickedDay).isSameOrAfter(
                                      vacation.startDate,
                                      "day"
                                    ) &&
                                    moment(props.clickedDay).isSameOrBefore(
                                      vacation.endDate,
                                      "day"
                                    )
                                ).vacationIdentifier
                              }
                            </span>
                            <span>·</span>
                            <span className={classes.VacationCalendarIcon}>
                              {VacationCalendarIcon}
                            </span>
                            <span className={classes.VacationDates}>
                              {dayjs(
                                employee.vacations.find(
                                  (vacation) =>
                                    moment(props.clickedDay).isSameOrAfter(
                                      vacation.startDate,
                                      "day"
                                    ) &&
                                    moment(props.clickedDay).isSameOrBefore(
                                      vacation.endDate,
                                      "day"
                                    )
                                ).startDate
                              ).format("D MMMM, YYYY")}
                              {employee.vacations.find(
                                (vacation) =>
                                  moment(props.clickedDay).isSameOrAfter(
                                    vacation.startDate,
                                    "day"
                                  ) &&
                                  moment(props.clickedDay).isSameOrBefore(
                                    vacation.endDate,
                                    "day"
                                  )
                              ).endDate !==
                                employee.vacations.find(
                                  (vacation) =>
                                    moment(props.clickedDay).isSameOrAfter(
                                      vacation.startDate,
                                      "day"
                                    ) &&
                                    moment(props.clickedDay).isSameOrBefore(
                                      vacation.endDate,
                                      "day"
                                    )
                                ).startDate && (
                                <>
                                  {" "}
                                  –{" "}
                                  {dayjs(
                                    employee.vacations.find(
                                      (vacation) =>
                                        moment(props.clickedDay).isSameOrAfter(
                                          vacation.startDate,
                                          "day"
                                        ) &&
                                        moment(props.clickedDay).isSameOrBefore(
                                          vacation.endDate,
                                          "day"
                                        )
                                    ).endDate
                                  ).format("D MMMM, YYYY")}
                                </>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className={classes.EmployeeActionsContainer}>
                        <button className={classes.EmployeeActionButton}>
                          <span className={classes.EmployeeActionIcon}>
                            {spreadIcon}
                          </span>
                        </button>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
          <div className={classes.BoxAction}>
            <Link to="/staff" style={{ textDecoration: "none", width: "100%" }}>
              <button className={classes.BoxActionButton}>
                <span className={classes.BoxActionText}>
                  View staff members
                </span>
                <span className={classes.BoxActionIcon}>{arrowRightIcon}</span>
              </button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};
export default CalendarModal;
