import React, { ReactNode } from "react";
import SideBar from "./SideBar";
import { useLocation } from "react-router-dom";
const Layout = (props: { children: ReactNode }) => {
  const location = useLocation();

  return (
    <div>
      <div style={{ position: "absolute", left: 0, top: 0 }}>
        {
          location.pathname !== "/login" &&
          location.pathname !== "/logout" &&
          location.pathname !== "/your-places" &&
          location.pathname !== "/forgot-password" &&
          !location.pathname.includes("/register") &&
          !location.pathname.includes("/reset-password") &&
          !location.pathname.includes("/email-verification") && <SideBar />}
      </div>
      {props.children}
    </div>
  );
};

export default Layout;
