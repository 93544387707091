import { useEffect, useState } from "react";
import { getAllStaffForDashboard } from "../../auth/api/requests";
import { scanIcon } from "../../icons/icons";
import Spinner from "../Spinner";
import QRcode from "../Staff/components/QRcode";
import { Employee, Restaurant } from "../Staff/StaffTypes";
import classes from "./Dashboard.module.css";
import KeyIndicators from "./KeyIndicators";
import StaffList from "./StaffList";
import Stock from "./Stock";
import Vacations from "./Vacations";
import { NotificationButton } from "../Statistics/Header";
import CalendarEmployeeDetails from "../Staff/OverviewCalendar/CalendarEmployeeDetails";
import TimeTable from "../Staff/OverviewCalendar/TimeTable";
type Props = {};
const Dashboard = (props: Props) => {
  const [qrButton, setQrButton] = useState(false);

  const [employees, setEmployees] = useState<Employee[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedRestaurant, setSelectedRestaurant] = useState<Restaurant>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const EmployeeResponse = await getAllStaffForDashboard();
        if (Array.isArray(EmployeeResponse)) {
          setEmployees(EmployeeResponse);
        } else {
          console.error(
            "getAllStaff did not return an array:",
            EmployeeResponse
          );
        }

        const storedRestaurant = JSON.parse(
          localStorage.getItem("selectedRestaurant") ?? "null"
        );

        if (storedRestaurant) {
          // Fetch the restaurant details based on the ID from your data source
          setSelectedRestaurant(storedRestaurant);
        }
        setTimeout(setLoading.bind(null, false), 500);
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    };
    fetchData();
  }, []); // Removed selectedRestaurantFromStorage from the dependency array

  const [activeTab, setActiveTab] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState<any>(null);

  console.log(activeTab);

  return (
    <>
      {qrButton ? (
        <QRcode setQrButton={setQrButton} />
      ) : (
        <>
          <div className={classes.Head}>
            <div className={classes.Heading}>
              <div className={classes.CompanyTitle}>
                <label className={classes.CompanyName}>
                  {selectedRestaurant?.name}
                </label>
                <span className={classes.CompanyStatus}></span>
              </div>
              <div className={classes.CompanyAddress}>
                <label className={classes.CompanyAddressText}>
                  {selectedRestaurant?.address}
                </label>
              </div>
            </div>
            <div className={classes.HeadActions}>
              <button
                className={classes.ScanButton}
                onClick={() => setQrButton(true)}
              >
                <span className={classes.ScanIcon}>{scanIcon}</span>
                <span className={classes.ScanText}>Scan worker</span>
              </button>

              <NotificationButton />
            </div>
          </div>
          <div className={classes.DashboardContainer}>
            <KeyIndicators />
            <StaffList
              employees={employees}
              setActiveTab={setActiveTab}
              selectedEmployee={selectedEmployee}
              setSelectedEmployee={setSelectedEmployee}
            />
            <div className={classes.StockAndVacation}>
              <Stock />
              <Vacations employees={employees} />
            </div>
          </div>
          {activeTab !== "" ? (
            <div className={classes.DashboardModal}>
              {activeTab === "details" ? (
                <CalendarEmployeeDetails
                  employee={selectedEmployee}
                  onClose={() => setActiveTab("")}
                  onBack={() => setActiveTab("")}
                />
              ) : activeTab === "time" ? (
                <TimeTable
                  employee={selectedEmployee}
                  onClose={() => setActiveTab("")}
                  onBack={() => setActiveTab("")}
                />
              ) : null}
            </div>
          ) : null}
        </>
      )}
      <Spinner loading={loading} />
    </>
  );
};
export default Dashboard;
