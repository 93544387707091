import storage from "../../utils/storage";
import { UserResponse } from "../types";
import api from "./apiInstance";

export type LoginCredentialsDTO = {
  email: string;
  password: string;
};

export const loginWithEmailAndPassword = async (
  data: LoginCredentialsDTO
): Promise<UserResponse> => {
  return api.post("/auth/login", data);
};

export async function handleUserResponse(response: UserResponse) {
  const { accessToken, refreshToken, user } = response.data;
  storage.setAccessToken(accessToken);
  storage.setRefreshToken(refreshToken);
  return user;
}
