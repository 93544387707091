import React, { useEffect, useRef, useState } from "react";
import classes from "./Clients.module.css";
import { arrowRightIcon, arrowLeftIcon } from "./../../icons/icons";
import TableClients from "./TableClients";
import moment, { Moment } from "moment";
import { useDispatch } from "react-redux";
import { reservationShowComponentActions } from "../../store/reservationShowComponent";
import { NotificationButton } from "../Statistics/Header";
import { Client } from "../Staff/StaffTypes";
import { getAllClients } from "../../auth/api/requests";
import ReactPaginate from "react-paginate";

const Clients: React.FC = () => {
  const title = "Clients";

  const [currentDate, setCurrentDate] = useState<Moment>(moment());
  const [nextDate, setNextDate] = useState<Moment>(moment());
  const [clients, setClients] = useState<Client[]>([]);

  const dispatch = useDispatch();

  dispatch(reservationShowComponentActions.setCurrentTable(currentDate));
  dispatch(reservationShowComponentActions.setCurrentTable(nextDate));

  const subtractCurrentMonth = (date) => {
    dispatch(
      reservationShowComponentActions.setDate(
        currentDate.subtract(1, "M").format()
      )
    );
    setCurrentDate(date.subtract(1, "M"));
  };

  const substractNextMonth = (date) => {
    if (currentDate.isBefore(nextDate)) {
      dispatch(
        reservationShowComponentActions.setDate(
          nextDate.subtract(1, "M").format()
        )
      );
      setNextDate(date.subtract(1, "M"));
    }
  };

  const addCurrentMonth = (date) => {
    if (currentDate.isBefore(nextDate)) {
      dispatch(
        reservationShowComponentActions.setDate(
          currentDate.add(1, "M").format()
        )
      );
      setCurrentDate(date.add(1, "M"));
    }
  };

  const addNextMonth = (date) => {
    if (nextDate.isBefore(moment().subtract(1, "M"))) {
      dispatch(
        reservationShowComponentActions.setDate(nextDate.add(1, "M").format())
      );
      setNextDate(date.add(1, "M"));
    }
  };

  const datepickerCurrent = (
    <div className={classes.date_picker}>
      <div
        onClick={() => {
          subtractCurrentMonth(currentDate.clone());
        }}
        className={classes.DatePickerArrow}
      >
        {arrowLeftIcon}
      </div>
      <div className={classes.date_value}>
        {currentDate.format("MMMM, YYYY")}
      </div>
      <div
        onClick={() => {
          addCurrentMonth(currentDate.clone());
        }}
        className={classes.DatePickerArrow}
      >
        {arrowRightIcon}
      </div>
    </div>
  );

  const datepickerNext = (
    <div className={classes.date_picker}>
      <div
        onClick={() => {
          substractNextMonth(nextDate.clone());
        }}
        className={classes.DatePickerArrow}
      >
        {arrowLeftIcon}
      </div>
      <div className={classes.date_value}>{nextDate.format("MMMM, YYYY")}</div>
      <div
        onClick={() => {
          addNextMonth(nextDate.clone());
        }}
        className={classes.DatePickerArrow}
      >
        {arrowRightIcon}
      </div>
    </div>
  );

  type Filter = {
    date: {
      startDate: string;
      endDate: string;
    };
    sortBy?: {
      column: string;
      order: string;
    };
    search?: string;
    pagination?: number;
  };

  const [totalItems, setTotalItems] = useState(0);
  const initialTotalItemsRef = useRef<number | null>(null);
  const [loading, setLoading] = useState(true);
  const [sortOptions, setSortOptions] = useState({
    column: "username",
    order: "No Order",
  });

  const [currentPage, setCurrentPage] = useState(0); // react-paginate uses zero-based indexing

  const [searchValue, setSearchValue] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const startOfDate = new Date(currentDate.format("YYYY-MM-DD"));
        startOfDate.setDate(1);

        const endOfDate = new Date(nextDate.format("YYYY-MM-DD"));
        endOfDate.setMonth(endOfDate.getMonth() + 1, 0);

        const startDate = startOfDate.toISOString().slice(0, 10);
        const endDate = endOfDate.toISOString().slice(0, 10);

        const filter: Filter = {
          date: {
            startDate,
            endDate,
          },
        };

        if (sortOptions.order !== "No Order") {
          filter.sortBy = sortOptions;
        }

        if (searchValue !== "") {
          filter.search = searchValue;
        }

        if (currentPage >= 0) {
          filter.pagination = currentPage * 10;
        }

        const clientsResponse = await getAllClients(filter);
        if (initialTotalItemsRef.current === null) {
          initialTotalItemsRef.current = clientsResponse.pagination.totalItems;
        }
        setClients(clientsResponse.data);
        setTotalPages(clientsResponse.pagination.totalPages);
        setTotalItems(initialTotalItemsRef.current ?? 0);
        setTimeout(setLoading.bind(null, false), 500);
      } catch (error) {
        console.error("Can't get clients:", error);
      }
    };
    fetchData();
  }, [currentDate, nextDate, sortOptions, searchValue, currentPage]);

  console.log(clients.length);

  return (
    <div className={classes.CommonClientsContainer}>
      <div className={classes.ClientsContainer}>
        <div className={classes.header}>
          <h1>{title}</h1>
          <div className={classes.clients}>
            {datepickerCurrent}
            {datepickerNext}

            <NotificationButton />
          </div>
        </div>
        <TableClients
          clients={clients}
          setSortOptions={setSortOptions}
          loading={loading}
          setSearchValue={setSearchValue}
        />
        {/* {clients.length > 9 ? ( */}
        {totalPages > 1 ? (
          <div className={classes.Pagination}>
            <span className={classes.NumberOfElements}>
              {/* Shown {clients.length} of {totalItems} items */}
            </span>
            <div className={classes.Pages}>
              <ReactPaginate
                pageCount={totalPages}
                pageRangeDisplayed={5}
                marginPagesDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={classes.PageList}
                activeClassName={classes.ActivePage}
                activeLinkClassName={classes.ActivePageText}
                pageClassName={classes.NotActivePage}
                pageLinkClassName={classes.NotActivePageText}
                breakLabel="..."
                renderOnZeroPageCount={null}
                previousClassName={classes.disabled}
                nextClassName={classes.disabled}
              />
            </div>
            <div></div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Clients;
