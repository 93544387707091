export const durationOptions = [
    { value: "20", label: "20min" },
    { value: "30", label: "30min" },
    { value: "40", label: "40min" },
    { value: "50", label: "50min" },
    { value: "60", label: "1h" },
    { value: "75", label: "1h15min" },
    { value: "90", label: "1h30min" },
    { value: "105", label: "1h45min" },
    { value: "120", label: "2h" },
    { value: "150", label: "2h30min" },
    { value: "180", label: "3h" },
    { value: "240", label: "4h" },
    { value: "300", label: "5h" },
    { value: "360", label: "6h" },
  ];
  