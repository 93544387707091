import React from "react";
import classes from "./StaffMembers.module.css";
import Title from "../Title";
import AddEmployeeButton from "./AddEmployee/AddEmployeeButton";
import { calendarIcon, deleteIcon, EditWorkerIcon } from "../../icons/icons";
import { Button, useMediaQuery } from "@mui/material";
import { Typography } from "@mui/material";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { Employee } from "./StaffTypes";
import { deleteUser } from "../../auth/api/requests";
import { toast } from "react-toastify";

type ScheduleIcon = {
  scheduleIcon: string | null;
  tagIcon: string | null;
};

type ScheduleIcons = {
  [key: string]: ScheduleIcon;
};

type StaffMembersProps = {
  employees: Employee[];
  handleEmployeeClick: (id: string) => void;
  isModalOpen: boolean;
  setIsModalOpen: (value) => void;
  setNewFetch: (value) => void;
};

const StaffMembers: React.FC<StaffMembersProps> = (props) => {
  const DEFAULT_IMAGE = "/staffImages/DefaultImage.svg";
  const calendarColor = "black";
  // console.log("🚀 ~ employees:", props.employees)

  const scheduleIcons: ScheduleIcons = {
    HOLIDAY: {
      scheduleIcon: "/scheduleIcons/holiday.svg",
      tagIcon: null,
    },
    DAY_OFF: {
      scheduleIcon: "/scheduleIcons/dayOff.svg",
      tagIcon: null,
    },
    WORKING: {
      scheduleIcon: "/scheduleIcons/working.svg",
      tagIcon: "/scheduleIcons/floorIcon.svg",
    },
    "Waiting Invite": {
      scheduleIcon: "/scheduleIcons/waitingInvite.svg",
      tagIcon: null,
    },
    NoSchedules: {
      scheduleIcon: "/scheduleIcons/noSchedules.svg",
      tagIcon: null,
    },
  };
  const GenerateEmployeeRows = () => {
    const isSmallScreen = useMediaQuery("(min-width: 768px)");
    const isMediumScreen = useMediaQuery("(min-width: 1024px)");
    const isLargeScreen = useMediaQuery("(min-width: 1440px)");

    // Calculate the number of employees per row based on the screen size
    const employeesPerRow = isLargeScreen
      ? 6
      : isMediumScreen
      ? 4
      : isSmallScreen
      ? 3
      : 1;

    const rows: JSX.Element[] = [];
    let currentRow: Employee[] = [];

    props.employees?.forEach((employee) => {
      if (currentRow.length < employeesPerRow) {
        currentRow.push(employee);
      } else {
        rows.push(renderRow(currentRow));
        currentRow = [employee];
      }
    });

    if (currentRow.length > 0) {
      rows.push(renderRow(currentRow));
    }

    return rows;
  };

  const renderRow = (rowEmployees: Employee[]) => {
    return (
      <div className={classes.EmployeeRow} key={rowEmployees[0].id}>
        {rowEmployees.map((employee) => {
          const todayDate = dayjs().format("YYYY-MM-DD");
          const todaySchedule = employee.staffSchedules?.find(
            (schedule) =>
              dayjs(schedule.date).format("YYYY-MM-DD") === todayDate
          );

          function capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
          }

          const scheduleStatusForShowing =
            employee.isVerified === true
              ? todaySchedule?.status === "WORKING"
                ? todaySchedule?.floor
                : todaySchedule?.status === "HOLIDAY" ||
                  todaySchedule?.status === "DAY_OFF"
                ? capitalizeFirstLetter(
                    todaySchedule?.status.replace("_", "-").toLowerCase()
                  )
                : "No schedules"
              : "Waiting Invite";
          const scheduleStatus = employee.isVerified
            ? todaySchedule?.status || "NoSchedules"
            : "Waitin Invite";

          const hasScheduleInDB = scheduleStatus !== "NoSchedules";
          const isHolidayOrDayOff =
            scheduleStatus === "HOLIDAY" || scheduleStatus === "DAY_OFF";

          const succes = () =>
            toast.success("Succes!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });

          const eroare = (text) =>
            toast.error(text, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });

          const handleDeleteUser = async (employeeId) => {
            try {
              await deleteUser(employeeId);
              succes();
              props.setNewFetch((prevState) => !prevState);
            } catch (error) {
              eroare(error);
            }
          };

          return (
            <div key={employee.id} className={classes.StaffMembersWorker}>
              <div className={classes.WorkerHead}>
                {employee.isVerified === true ? (
                  <div className={classes.EmployeeImageContainer}>
                    <img
                      className={classes.StaffMembersEmployeeImage}
                      src={
                        employee.avatar?.startsWith("avatar_")
                          ? require(`../../assets/${employee?.avatar}`).default
                          : employee?.avatar !== null &&
                            !employee?.avatar?.startsWith("avatar_")
                          ? employee?.avatarUrl
                          : DEFAULT_IMAGE
                      }
                      alt={employee.username}
                    />
                    <div className={classes.ScheduleIcon}>
                      {scheduleIcons[scheduleStatus] &&
                        scheduleIcons[scheduleStatus].scheduleIcon && (
                          <img
                            src={
                              scheduleIcons[scheduleStatus]
                                .scheduleIcon as string
                            }
                            alt={scheduleStatus}
                          />
                        )}
                    </div>
                  </div>
                ) : (
                  <div className={classes.EmployeeImageContainer}>
                    <img
                      className={classes.StaffMembersEmployeeImage}
                      src={DEFAULT_IMAGE}
                      alt="Default"
                    />
                    <div className={classes.ScheduleIcon}>
                      {!hasScheduleInDB && employee.isVerified
                        ? scheduleIcons["NoSchedules"].scheduleIcon && (
                            <img
                              src={scheduleIcons["NoSchedules"].scheduleIcon}
                              alt="NoSchedules"
                            />
                          )
                        : scheduleIcons["Waiting Invite"].scheduleIcon && (
                            <img
                              src={scheduleIcons["Waiting Invite"].scheduleIcon}
                              alt="Waiting Invite"
                            />
                          )}
                      {isHolidayOrDayOff &&
                        scheduleIcons[scheduleStatus] &&
                        scheduleIcons[scheduleStatus].scheduleIcon && (
                          <img
                            src={
                              scheduleIcons[scheduleStatus]
                                .scheduleIcon as string
                            }
                            alt={scheduleStatus}
                          />
                        )}
                      {scheduleStatus === "WORKING" &&
                        scheduleIcons[scheduleStatus] &&
                        scheduleIcons[scheduleStatus].scheduleIcon && (
                          <img
                            src={
                              scheduleIcons[scheduleStatus]
                                .scheduleIcon as string
                            }
                            alt={scheduleStatus}
                          />
                        )}
                    </div>
                  </div>
                )}
                {employee.isVerified === false ? (
                  <button
                    className={classes.EditWorkerButton}
                    onClick={() => {
                      handleDeleteUser(employee.id);
                    }}
                  >
                    <div
                      className={classes.EditWorkerIcon}
                      style={{ color: "#020202" }}
                    >
                      {deleteIcon}
                    </div>
                  </button>
                ) : (
                  <button
                    className={classes.EditWorkerButton}
                    onClick={() => {
                      props.handleEmployeeClick(employee.id);
                    }}
                  >
                    <div className={classes.EditWorkerIcon}>
                      {EditWorkerIcon}
                    </div>
                  </button>
                )}
              </div>
              <div className={classes.WorkerContent}>
                <div className={classes.WorkerContentTitle}>
                  <div className={classes.WorkerContentTitleTextContainer}>
                    <p className={classes.WorkerContentTitleText}>
                      {employee.username}
                    </p>
                  </div>
                  <div
                    className={`${classes.WorkerTag} ${
                      scheduleStatus &&
                      scheduleIcons[scheduleStatus] &&
                      classes[scheduleStatus]
                    }`}
                  >
                    {scheduleIcons[scheduleStatus] &&
                      scheduleIcons[scheduleStatus].tagIcon && (
                        <div className={classes.TagIcon}>
                          <img
                            className={classes.TagIconImage}
                            src={
                              scheduleIcons[scheduleStatus].tagIcon as string
                            }
                            alt="Tag Icon"
                          />
                        </div>
                      )}
                    <p className={classes.WorkerTagText}>
                      {scheduleStatusForShowing}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className={classes.StaffMembersContainer}>
      <div className={classes.StaffMembersHead}>
        <div className={classes.StaffMembersHeading}>
          <Title
            title="Staff members"
            subtitle={`${props.employees.length} employees`}
          />
        </div>
        <div className={classes.StaffMembersHeadActions}>
          <Link
            to="/staff/overview-calendar"
            state={{
              employees: props.employees,
            }}
          >
            <Button
              startIcon={calendarIcon}
              variant="outlined"
              size="large"
              className={classes.calendarButton}
              style={{ color: calendarColor }}
              disableRipple
            >
              <Typography className={classes.calendarButtonText}>
                Overview calendar
              </Typography>
            </Button>
          </Link>
          <AddEmployeeButton
            text="Add new employee"
            isModalOpen={props.isModalOpen}
            setIsModalOpen={props.setIsModalOpen}
          />
        </div>
      </div>
      <div className={classes.RowsContainer}>{GenerateEmployeeRows()}</div>
    </div>
  );
};

export default StaffMembers;
